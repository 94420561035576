angular.module('boc')
  .component('chatter', {
    template: `
      <section class="Chatter" ng-class="{ 'is-active': $ctrl.active }">
        <header class="Chatter__header" ng-click="$ctrl.toggle()">
          <h1 class="Chatter__room-title">{{$ctrl.room.name}}</h4>
          <h2 class="Chatter__banner">
            <span class="Chatter__header-count">{{ $ctrl.newCount }}</span>
            <img class="Chatter__header-title" src="/img/logos/chatter-title.svg" />
            <span class="Chatter__header-link" ng-if="!$ctrl.active">View Chatter</span>
            <span class="Chatter__header-link" ng-if="$ctrl.active">Hide Chatter</span>
          </h2>
        </header>
        <ul class="Chatter__posts">
          <li class="ChatterPost" ng-repeat="msg in $ctrl.messages">
            <div class="ChatterPost__content">
              <div ng-if="msg.user.avatar" class="Avatar--small" style="background-image:url('{{ msg.user.avatar }}')"></div>
              <div ng-if="!msg.user.avatar" class="Avatar--small" alt="{{ msg.user.username | initials }}"></div>
              <div class="ChatterPost__info">
                <span class="ChatterPost__user">{{ msg.user.username }}</span>
                <span class="ChatterPost__date">{{ msg.createdAt | date:'EEEE LLLL d, h:mm a' }}</span>
              </div>
              <div class="ChatterPost__message">{{ msg.message }}</div>
              <div ng-if="msg.id > 0">
                <button ng-if="!msg.flagged" class="ChatterPost__link" ng-click="$ctrl.flag(msg)">Flag as Inappropriate</button>
                <button ng-if="msg.flagged" class="ChatterPost__flagged">This message was flagged as inappropriate.</button>
              </div>
              <div ng-if="msg.id < 0 && $ctrl.failed">
                Sending failed:
                <button class="ChatterPost__link" ng-click="$ctrl.cancel(msg)">Cancel</button>
                |
                <button class="ChatterPost__link" ng-click="$ctrl.save(msg)">Retry</button>
              </div>
              <div ng-if="msg.id < 0 && $ctrl.saving" class="ChatterPost__sending">{{ $ctrl.saving }}</div>
            </div>
          </li>
        </ul>
        <div class="Chatter__footer">
          <div grid="tight nowrap">
            <div grid="shrink@phone"></div>
            <div grid="1/1@phone">
              <input class="TextField" type="text" ng-model="message" ng-keydown="$ctrl.keydown($event, message)">
            </div>
            <div grid="shrink@phone">
              <button class="Button--primary-small-full" ng-click="$ctrl.post(message)">
                <span class="is-visible@tablet">Add My</span> Comment
              </button>
            </div>
          </div>
        </div>
      </section>
    `,

    controller: function(_, $scope, $element, Chatter, $window, User, $timeout, $interval) {
      const $ctrl = this
      const POLL_INTERVAL = 15000 // 15 seconds

      let chatterKey = ''
      let tempMessageId = 0
      let postsElement
      let pollInterval
      let latestMessageId

      const loadingMessages = [
        'Hold on to your butts…',
        'Punch it, Chewie…',
        'Saving…',
        'Saving…',
        'Saving…',
        'Saving…',
      ]

      $ctrl.messages = []
      $ctrl.post = post
      $ctrl.save = save
      $ctrl.flag = flag
      $ctrl.toggle = toggle
      $ctrl.keydown = keydown

      $scope.$on('$routeChangeSuccess', updateRoomName)

      $ctrl.$onInit = function() {
        postsElement = $element.find('.Chatter__posts')
      }

      function updateRoomName() {
        const path = $window.location.pathname.slice(1).replace(/\//g, ':')
        const root = path.slice(0, path.indexOf(':'))
        chatterKey = path
        if (root === 'topfive' || root === 'blockbuster') chatterKey = root
        return update()
      }

      function update() {
        return Chatter.list(chatterKey)
          .then(updateSuccess)
      }

      function updateSuccess(room) {
        $ctrl.room = room
        $ctrl.messages = room.messages
        var lastViewed = $ctrl.room.lastViewed || 0
        $ctrl.newCount = $ctrl.messages.filter(message => message.createdAt > lastViewed).length
      }

      function post(msg) {
        msg = _.trim(msg)
        if (!msg) return

        const message = {
          created_at: (new Date()).toISOString(),
          id: --tempMessageId,
          message: msg,
          user: User.current
        }
        $scope.message = ''
        $ctrl.messages.unshift(message)
        scrollToBottom()
        return save(message)
      }

      function scrollToBottom() {
        if (!postsElement) return
        // Animate to the bottom after new post has rendered
        $timeout(postsElement.animate.bind(postsElement, { scrollTop: postsElement[0].scrollHeight }, 500), 0, false)
      }

      function save(message) {
        $ctrl.saving = loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
        return Chatter.post(chatterKey, message.message)
          .then($timeout.bind($timeout, function(){}, 1000)) // This is to give time to read the saving message...
          .then(saveSuccess.bind(null, message), saveError)
          .then(update)
          .then(markViewed)
      }

      function saveSuccess(message) {
        message.id = Infinity // temp while refreshing
        $ctrl.failed = $ctrl.saving = null
      }

      function saveError() {
        $ctrl.failed = true
        $ctrl.saving = false
      }

      function poll() {
        if (!$ctrl.saving) return update()
      }

      function flag(msg) {
        return Chatter
          .flag(msg.id)
          .then(() => console.log('message flagged'))
      }

      function markViewed() {
        return Chatter.markViewed(chatterKey)
      }

      function toggle() {
        if ($ctrl.active = !$ctrl.active) {
          markViewed()
          update()
          pollInterval = $interval(poll, POLL_INTERVAL)
        } else {
          $interval.cancel(pollInterval)
        }
      }

      function keydown(e, message) {
        // Submit on enter, but not shift enter
        if (e.key !== 'Enter' || e.shiftKey) return
        e.preventDefault()
        return $ctrl.post(message)
      }
    }

  })
