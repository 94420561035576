angular.module('services')
  .factory('GameMovie', function(_, Service, Movie, Time, $injector, $http) {

    const statuses = [
      { label: '', value: null },
      { label: 'Top', value: 'top' },
      { label: 'New', value: 'new' },
      { label: 'Expanded', value: 'expanded' },
      { label: 'Hidden', value: 'hidden' }
    ]

    class GameMovieService extends Service {

      constructor() {
        super()
      }

      url(params) {
        return super.url(params, '/api/game-movies')
      }

      get statuses() {
        return statuses
      }
    }

    class GameMovie extends Movie.Model {
    }

    var gameMovieApi = Service.register('GameMovie', GameMovieService, GameMovie)

    return gameMovieApi

  })
