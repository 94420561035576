angular.module('boc')
  .component('profile', {
    template: `
      <form name="profileForm" ng-submit="$ctrl.save()">
        <div grid="loose spaced nowrap align-baseline">
          <div grid-el grid="shrink@phone">
            <h1 class="PageTitle--small">My Account</h1>
          </div>
          <div ng-if="profileForm.$dirty" grid-el grid="shrink@phone">
            <button type="submit" class="Button--primary-small">Save Changes</button>
          </div>
        </div>
        <div grid="loose">
          <div grid-el grid="1/1@phone 1/2@tablet">
            <h2 class="SectionTitle">User Identity</h2>
            <div class="Field">
              <label for="user_username" class="Field__label">Display Name</label>
              <label class="TextField--icon">
                <input id="user_username" type="text" ng-model="$ctrl.user.username" />
                <svg-img class="TextField__icon" use="person"></svg-img>
              </label>
              <label class="HelpText">Your display name will represent your profile to other players across boxofficechamps.com.</label>
            </div>
            <div class="Field">
              <label for="user_avatar" class="Field__label">Avatar</label>
              <label class="HelpText">We use Gravatar to provide your user avatar, but you can also upload a unique avatar if you choose.</label>
              <span
                ng-if="$ctrl.user.avatar"
                class="Avatar--large"
                style="background-image:url({{ $ctrl.user.avatar }})">
              </span>
              <span
                ng-if="!$ctrl.user.avatar"
                class="Avatar--large"
                alt="{{ $ctrl.user.username | initials }}">
              </span>
              <label class="FileField">
                <input id="user_avatar" type="file" />
                <span class="FileField__label">Upload a New Avatar</span>
              </label>
            </div>
          </div>
          <div grid-el grid="1/1@phone 1/2@tablet">
            <h2 class="SectionTitle">Login Info</h2>
            <div class="Field">
              <label for="user_email" class="Field__label">Email Address</label>
              <label class="TextField--icon">
                <input id="user_email" type="text" ng-model="$ctrl.user.email" />
                <svg-img class="TextField__icon" use="envelope"></svg-img>
              </label>
              <label class="HelpText">Your email address will be used to log into the site and retrieve forgotten passwords.</label>
            </div>
            <div class="Field">
              <label for="user_password" class="Field__label">Password</label>
              <label class="TextField--icon">
                <input
                  id="user_password"
                  type="password"
                  ng-model="$ctrl.password"
                  ng-pattern="$ctrl.passwordValidation"
                />
                <svg-img class="TextField__icon" use="lock"></svg-img>
              </label>
              <label class="HelpText">Choose something unique and easy to remember.</label>
            </div>
          </div>
        </div>
      </form>
    `,
    controller: function(_, User) {

      var $ctrl = this
      var PASSWORD = 'invalidpassword'

      $ctrl.$onInit = function() {
        $ctrl.passwordValidation = /^[^invalidpassword]$/
        setupUser(User.current)
      }

      $ctrl.save = function() {
        var password = $ctrl.password
        var userData = angular.copy($ctrl.user)
        if (password)
        console.log("Save User", $ctrl.user)

      }

      function setupUser(user) {
        User.current = user
        $ctrl.user = angular.copy(_.pick(user, 'email', 'avatar', 'username', 'realname'))
        $ctrl.password = PASSWORD
      }

    }
  })
