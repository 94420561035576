angular.module('services')
  .factory('GameTeam', function(_, Service, Model, Movie, Time) {

    class GameTeamService extends Service {

      constructor() {
        super()
      }

      url(params) {
        return super.url(params, '/api/game-teams')
      }
    }

    class GameTeam extends Model {

      get associations() {
        return {
          team: 'Team'
        }
      }

    }

    return Service.register('GameTeam', GameTeamService, GameTeam)

  })
