angular.module('filters')

  .service('weekToDateFn', function(moment, Time) {
    return function(offset = 0) {
      return function(week, format) {
        var m = moment(Time.weekToDate(week)).add(offset, 'days')
        return format === null ? m : m.isValid() ? m.format(format || 'dddd, MMMM D') : ''
      }
    }
  })

  .filter('weekToMonday', function(weekToDateFn) {
    return weekToDateFn(0)
  })

  .filter('weekToFriday', function(weekToDateFn) {
    return weekToDateFn(4)
  })

  .filter('weekToSunday', function(weekToDateFn) {
    return weekToDateFn(6)
  })
