angular.module('boc')

  .directive('stickyContainer', function() {

    return {
      restrict: 'A',
      controller: function($element) {
        this.$element = $element
        // The code below prevents margin collapse, which can cause positioning issues
        var css = {}
        if (parseInt($element.css('paddingTop'), 10) === 0) css.paddingTop = '.05px'
        if (parseInt($element.css('paddingBottom'), 10) === 0) css.paddingBottom = '.05px'
        $element.css(css)
      }
    }

  })

  .directive('sticky', function(Tether) {

    return {
      require: '^stickyContainer',
      restrict: 'A',
      link: function($scope, $element, $attrs, container) {
        new Tether({
          element: $element,
          target: container.$element,
          attachment: 'top left',
          targetAttachment: 'top left',
          constraints: [
            {
              to: 'window',
              attachment: 'together',
              pin: true
            }
          ]
        })
      }
    }

  })
