angular.module('boc')
  .component('gameLeaderboard', {

    template: `
      <loader success="$ctrl.gameData.loaded" failure="$ctrl.failure" on-retry="$ctrl.gameData.retry()">
        <div grid="loose">
          <!-- enable this once we have the estimate info -->
          <div ng-if="false" grid-el grid="1/1@phone">
            <div class="Notification--general">
              <p>
                <b>Results estimated through Monday (00/00).</b>
                Next Update: Tuesday Afternoon (00/00).
              </p>
            </div>
          </div>
          <div ng-if="$ctrl.gameData.gameTeam" grid-el grid="1/1@phone">
            <div class="TeamSummary">
              <span
                ng-if="!$ctrl.gameData.gameTeam.avatar"
                class="TeamSummary__avatar"
                alt="{{ $ctrl.gameData.gameTeam.abbrev }}">
              </span>
              <span
                ng-if="$ctrl.gameData.gameTeam.avatar"
                class="TeamSummary__avatar"
                style="background-image:url({{ $ctrl.gameData.gameTeam.avatar }});">
              </span>
              <h1 class="TeamSummary__name">{{ $ctrl.gameData.gameTeam.name }}</h1>
              <ul class="TeamSummary__info">
                <li class="TeamSummary__info-primary">{{ $ctrl.gameData.gameTeam.revenue | revenue }}</li>
                <li class="TeamSummary__info-secondary">{{ $ctrl.gameData.seasonRankOfTotal }}</li>
              </ul>
            </div>
          </div>
          <div grid-el grid="1/1@phone">
            <table class="DataTable">
              <thead>
                <tr ng-class="{ 'is-open': $ctrl.sortOpen }" ng-click="$ctrl.sortOpen = !$ctrl.sortOpen">
                  <th sort-by="seasonRank" sort-nulls-asc="last" sort-nulls-desc="first" sort-params="$ctrl.params">
                    <span ng-if="!$ctrl.isLatestWeek">Rank ({{ $ctrl.gameData.season.wNum() }})</span>
                    <span ng-if="$ctrl.isLatestWeek">Rank</span>
                  </th>
                  <th sort-by="name" sort-params="$ctrl.params" class="show@tablet"><!-- avatar --></th>
                  <th sort-by="name" sort-params="$ctrl.params">
                    <span ng-if="$ctrl.gameData.league.gameType === 'topfive'">Player</span>
                    <span ng-if="$ctrl.gameData.league.gameType === 'studio'">Studio</span>
                  </th>
                  <th class="is-numeric" sort-by="revenue" sort-nulls-asc="first" sort-nulls-desc="last" sort-params="$ctrl.params">
                    <span ng-if="$ctrl.gameData.week < $ctrl.Time.currentWeek">Season Total ({{ $ctrl.gameData.season.wNum() }})</span>
                    <span ng-if="$ctrl.gameData.week >= $ctrl.Time.currentWeek">Season Total</span>
                  </th>
                  <th class="is-numeric" sort-by="revenueChange" sort-nulls-asc="first" sort-nulls-desc="last" sort-params="$ctrl.params">
                    <span ng-if="$ctrl.gameData.week < $ctrl.Time.currentWeek">This Week ({{ $ctrl.gameData.season.wNum() }})</span>
                    <span ng-if="$ctrl.gameData.week >= $ctrl.Time.currentWeek">This Week</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr ng-repeat="gameTeam in $ctrl.gameData.gameTeams">
                  <td ng-class="{ 'is-active': $ctrl.params.sortBy === 'seasonRank' }">{{ gameTeam.seasonRank || '-' }}</td>
                  <td ng-class="{ 'is-active': $ctrl.params.sortBy === 'name' }">
                    <span ng-if="!gameTeam.avatar" class="DataTable__avatar" alt="{{ gameTeam.abbrev }}"></span>
                    <span ng-if="gameTeam.avatar" class="DataTable__avatar" style="background-image:url({{ gameTeam.avatar }});"></span>
                  </td>
                  <td ng-class="{ 'is-active': $ctrl.params.sortBy === 'name' }" class="DataTable__primary">
                    <a ng-link="['Picks', {userId: gameTeam.userId}]" class="Link">{{ gameTeam.name }}</a>
                  </td>
                  <td ng-class="{ 'is-active': $ctrl.params.sortBy === 'revenue' }" class="DataTable__secondary is-numeric">{{ gameTeam.revenue | revenue }}</td>
                  <td ng-class="{ 'is-active': $ctrl.params.sortBy === 'revenueChange' }" class="DataTable__secondary is-numeric">{{ gameTeam.revenueChange | revenue }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </loader>
    `,

    controller: function($q, $scope, gameData, Time, $window) {
      var $ = angular.element
      var $win = $($window)
      var $ctrl = this
      var params = $ctrl.params = { sortBy: 'revenue', sortDir: 'DESC NULLS LAST' }
      var activated

      $ctrl.gameData = gameData
      $ctrl.Time = Time
      $ctrl.sortOpen = false
      $ctrl.$routerOnActivate = $ctrl.$routerOnReuse = load

      $scope.$watchCollection('$ctrl.params', watch)
      $win.on('resize', resize)
      $scope.$on('$destroy', $win.off.bind($win, 'resize', resize))

      function resize() {
        $ctrl.sortOpen = false
      }

      function watch() {
        if (activated) load()
      }

      function load() {
        activated = true
        gameData.startUpdate()
        return $q.all([
          gameData.season.getTeamCount(),
          gameData.getGameTeam(),
          gameData.getGameTeams($ctrl.params)
        ])
        .then(loadSuccess, loadError)
        .finally(gameData.endUpdate)
      }

      function loadSuccess() {
        $ctrl.isLatestWeek = gameData.week === Math.min($ctrl.Time.currentWeek, gameData.season.endWeek)
      }

      function loadError(err) {
        $ctrl.failure = err
      }

    }

  })
