angular.module('boc')

  .directive('sortBy', function() {
    return {
      restrict:'A',
      scope: {
        key: '@sortBy',
        params: '=sortParams',
        sortNullsAsc: '@',
        sortNullsDesc: '@'
      },
      bindToController: true,
      controllerAs: '$ctrl',
      controller: function($scope, $element) {

        var $ctrl = this
        $element.on('click', $scope.$apply.bind($scope, sort))
        $scope.$watch('$ctrl.params.sortBy', setClass)

        function sort() {
          var lastKey = $ctrl.params.sortBy
          var lastDir = $ctrl.params.sortDir || ''
          var sortDesc = lastKey === $ctrl.key && lastDir.indexOf('ASC') !== -1
          var sortDir =  sortDesc ? 'DESC' : 'ASC'
          var nullSort = sortDir === 'ASC' ? $ctrl.sortNullsAsc : $ctrl.sortNullsDesc

          if (nullSort) sortDir += ' NULLS ' + nullSort.toUpperCase()

          $ctrl.params.sortBy = $ctrl.key
          $ctrl.params.sortDir = sortDir
        }

        function setClass() {
          $element.toggleClass('is-active', $ctrl.params.sortBy === $ctrl.key)
        }

      }
    }
  })
