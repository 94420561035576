;(function(Tether) {

  // Selet links based on current url
  function currentSection(i, el) {
    return window.location.href.indexOf(el.href) !== -1
  }
  $('[href]').filter(currentSection).addClass('is-selected')

  // Find sticky elements (server rendered) and make them sticky
  $('[sticky-header]').each(function() {
    var element = $(this)
    var target = element.clone().empty().insertAfter(element)
    var tether = new Tether({
      element: element,
      target: target,
      attachment: 'top left',
      targetAttachment: 'top left',
      constraints: [
        {
          to: 'window',
          attachment: 'together',
          pin: true
        }
      ]
    })

    function resize() {
      element.outerWidth(target.outerWidth())
      target.outerHeight(element.outerHeight())
    }

    $(window).on('resize', resize)
    resize()
    tether.position()
  })

  // Delegate toggle handlers - Must delecate to #App to fix issue on iOS
  // where event don't bubble to the body.
  $('#App').on('click', '[boc-toggle], [boc-toggle-close], [boc-toggle-open]', function(e) {
    var clicked = $(e.currentTarget);
    var openEls = getToggles('boc-toggle-open');
    var toggleEls = getToggles('boc-toggle').not(openEls);
    var closeEls = getToggles('boc-toggle-close').not(toggleEls).not(openEls);
    var activate = !!openEls.length || !clicked.hasClass('is-active');

    toggle(closeEls, false);
    toggle(toggleEls, activate);
    toggle(clicked, activate);
    toggle(openEls, true);

    function toggle(el, bool) {
      el.toggleClass('is-active', bool)
        .toggleClass('is-not-active', !bool);
    }

    function getToggles(attrName) {
      var val = clicked.attr(attrName)
      var names = val ? val.split(/\s+/g) : [];
      var selector = _.map(names, function(n) {
        return '[boc-toggle-name~="'+n+'"]'
      }).join(',')
      return $(selector)
    }

  })

})(window.Tether);
