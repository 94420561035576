angular.module('services')
  .factory('Model', function(_, $q) {

    class Collection {

      constructor(Model, models, associations) {
        _.bindAll(this, 'add', 'remove')
        this.associations = associations || {}
        this.data = _.extend([], _.pick(this, 'add', 'remove'))
        this.Model = Model
        if (models) this.add(models)
        return this.data
      }

      add(model) {
        if (_.isArray(model)) return _.each(model, this.add)
        var index = this.data.indexOf(model)
        if (this.data.indexOf(model) !== -1) return
        var attrs = _.extend({}, model, this.associations)
        this.data[this.data.length] = this.Model.createInstance(attrs)
      }

      remove(model) {
        if (_.isArray(model)) return _.each(model, this.remove)
        var index = this.data.indexOf(model)
        if (index !== -1) this.data.splice(index, 1)
      }

    }

    class Model {

      constructor(attrs) {
        _.defaults(this, attrs)
        this.syncAssociations()
      }

      save(config){
        return this.service.save(this.serialize(), config)
      }

      destroy(config){
        return this.id ? this.service.destroy(this.id, config) : $q.resolve()
      }

      serialize() {
        return this.service.serialize(this)
      }

      defineProperty(name, val, ModelClass, associations) {
        delete this[name]
        if (val && ModelClass && !(val instanceof ModelClass)) {
          val = ModelClass.createInstance(_.extend({}, val, associations))
        }
        var model = this
        Object.defineProperty(this, name, {
          configurable: true,
          get: _.constant(val), // TODO: this just sets the association prop... changing the foreign key has no affect on this
          set: function(val) {
            model.defineProperty(name, val, ModelClass, associations)
          }
        })
      }

      defineNestedProperty(name, path) {
        Object.defineProperty(this, name, { get: _.get.bind(_, this, path) })
      }

      defineGetterSetter(name, get, set) {
        Object.defineProperty(this, name, { get: get, set: set || angular.noop })
      }

      defineCollection(name, className, associations) {
        var modelData = [].concat(this[name] || [])
        var modelClass = this.classes[className]
        this.defineProperty(name, new Collection(modelClass, modelData, associations))
      }

      get associations() {
        return {}
        // EXAMPLE
        // return {
        //   season: 'Season', // match the name of a registered model class
        //   league: 'season.league', // or pass a nested path
        // }
      }

      syncAssociations() {
        var model = this
        var classes = model.classes
        var modelAssociations = model.associations

        function findOtherProp(otherAssociations) {
          for (var prop in otherAssociations) {
            if (otherAssociations[prop] === model.className) return prop
          }
        }

        _.each(modelAssociations, (val, name) => {
          // Handle collections and models
          var Association = classes[val]
          if (Association) {
            var associations = {}
            var otherAssociations = Association.prototype.associations
            var otherProp = findOtherProp(otherAssociations)
            if (otherProp) associations[otherProp] = model
            // For now, we assume collections have a different key than their singular model class name
            if (name.toLowerCase() !== val.toLowerCase()) model.defineCollection(name, val, associations)
            else model.defineProperty(name, model[name] || null, Association, associations)
          }
          // Handle custom types
          else if (name === val) model.defineProperty(name, model[name] || null) // set later
          else if (typeof val === 'string') model.defineNestedProperty(name, val) // nested prop
          else if (typeof val === 'function') model.defineGetterSetter(name, val, val) // getterSetter
          else model.defineProperty(name, val) // static value
        })
      }

    }

    Model.createInstance = function(attrs) {
      return attrs instanceof this ? attrs : new this(attrs)
    }

    return Model

  })
