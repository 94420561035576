angular.module('services')
  .factory('SeasonMovie', function(_, Service, Movie, Time, $injector, $http) {

    class SeasonMovieService extends Service {

      constructor() {
        super()
      }

      url(params) {
        var url = `/api/seasons/${params.seasonId}/movies`
        return params.movieId ? `${url}/${params.movieId}` : url
      }

      all(seasonId) {
        return this.get({seasonId: seasonId, movieId: 'all'})
      }

      // Get's movies available for the given week, grouped based on game type (server lookup)
      available(seasonId, week) {
        var service = this
        var config = { seasonId: seasonId, movieId: 'available', params: { week: week } }
        return $http
          .get(this.url(config), config)
          .then(data => _.map(data.data, service.deserialize))
      }
    }

    class SeasonMovie extends Movie.Model {

      get associations() {
        return {
          season: 'gameData.season',
          league: 'gameData.league',
          gameType: 'league.gameType'
        }
      }

      get gameData() {
        return $injector.get('gameData')
      }

      get hasOpened() {
        return Time.currentWeek > this.releaseWeek || (Time.currentWeek === this.releaseWeek && Time.currentDay >= 5) // friday or after
      }

      get hasNotOpened() {
        return !this.hasOpened
      }

      get weekCount() {
        var season = this.season || {}
        var releaseWeek = this.releaseWeek
        if (!season || !releaseWeek) return null
        if (releaseWeek > season.endWeek) return 0
        if (releaseWeek < season.startWeek) return releaseWeek - season.startWeek
        return season.endWeek - releaseWeek + 1
      }

      get weekCountDisplay() {
        var season = this.season || {}
        var count = this.weekCount
        if (!season || !count) return ''
        if (count === 1) return '1 Week'
        if (count === -1) return '-1 Week'
        return count + ' Weeks'
      }

    }

    var seasonMovieApi = Service.register('SeasonMovie', SeasonMovieService, SeasonMovie)

    return seasonMovieApi

  })
