angular.module('boc')
  .component('movieCard', {

    bindings: {
      movie: '<',
      onSelect: '&'
    },

    template: `
      <div class="MovieCard">
        <button ng-click="$ctrl.selectMovie($ctrl.movie)" class="MovieCard__select">Select</button>
        <div ng-click="$ctrl.showDetails()" class="MovieCard__pick" title="{{ movie.title }}">
          <img
            ng-if="$ctrl.movie.poster"
            class="MovieCard__pick-image"
            alt="{{ $ctrl.movie.title }}"
            ng-src="{{ $ctrl.movie.poster }}"
          />
          <div
            ng-if="!$ctrl.movie.poster"
            class="MovieCard__pick-image"
            alt="{{ $ctrl.movie.title }}">
          </div>
        </div>
        <div ng-if="$ctrl.isTopfive && $ctrl.gameData.week" class="MovieCard__weekend-num">
          {{ $ctrl.movie.weekendDisplay($ctrl.gameData.week) }}
        </div>
        <movie-history
          ng-if="$ctrl.isTopfive && $ctrl.movie.recentEarnings.length"
          movie="$ctrl.movie">
        </movie-history>
        <div ng-if="$ctrl.isStudio" class="MovieCard__release-date--select">
          {{ $ctrl.movie.opensDisplay }}
          <span class="MovieCard__release-day">({{ $ctrl.movie.opensDay }})</span>
          <span
            ng-if="$ctrl.movie.weekCount"
            ng-class="{
              'MovieCard__week-count': $ctrl.movie.weekCount > 0,
              'MovieCard__week-count--negative': $ctrl.movie.weekCount < 0,
            }"
            ng-bind="$ctrl.movie.weekCountDisplay">
          </span>
        </div>
      </div>
      <div ng-if="$ctrl.showingDetails" ng-click="$ctrl.hideDetails($event)" class="MoviePopup__overlay">
        <div class="MoviePopup">
          <div class="MoviePopup__content">
            <div class="MoviePopup__tags">
              <ul class="Tags">
                <li class="Tag--primary">{{ $ctrl.movie.releaseStatus }}</li>
                <li class="Tag">
                  <span>{{ $ctrl.movie.opensDisplay }}</span>
                  <span>({{ $ctrl.movie.opensDay }})</span>
                </li>
              </ul>
            </div>
            <h3 class="MoviePopup__title">{{ $ctrl.movie.title }}</h3>
            <div ng-if="$ctrl.movie.trailer" class="MoviePopup__trailer">
              <div class="MoviePopup__trailer-video">
                {{ $ctrl.movie.trailer }}
              </div>
            </div>
            <div ng-if="!$ctrl.movie.trailer && $ctrl.movie.backdrop" class="MoviePopup__trailer">
              <div class="MoviePopup__trailer-image" style="background-image: url('{{$ctrl.movie.backdrop}}');"></div>
            </div>
            <div class="MoviePopup__summary">
              <div ng-if="$ctrl.movie.poster" class="MoviePopup__summary-poster">
                <img ng-src="{{ $ctrl.movie.poster }}" />
              </div>
              <ul class="MoviePopup__summary-items">
                <li class="MoviePopup__summary-item">
                  <h4 class="Subheading--small">Release Date</h4>
                  <p class="Text--small">
                    <span>{{ $ctrl.movie.opensDisplay }}</span>
                    <span>({{ $ctrl.movie.opensDay }})</span>
                  </p>
                </li>
                <li class="MoviePopup__summary-item">
                  <h4 class="Subheading--small">Runtime</h4>
                  <p class="Text--small">{{ $ctrl.movie.runtime || 'Unknown' }}</p>
                </li>
                <li class="MoviePopup__summary-item">
                  <h4 class="Subheading--small">MPAA Rated</h4>
                  <p class="Text--small">{{ $ctrl.movie.mpaaRating || 'Not Rated' }}</p>
                </li>
              </ul>
            </div>
            <div ng-if="$ctrl.movie.overview" class="MoviePopup__overview">
              <h4 class="Subheading--small">Overview</h4>
              <p class="Text--small">{{ $ctrl.movie.overview }}</p>
            </div>
            <div class="MoviePopup__credits">
              <div ng-if="$ctrl.movie.cast.length" class="MoviePopup__cast">
                <h4 class="Subheading--small">Cast</h4>
                <p class="Text--small">
                  <span ng-repeat="castMember in $ctrl.movie.cast | limitTo:6">{{ castMember.name }}<br></span>
                </p>
              </div>
              <div class="MoviePopup__crew">
                <h4 ng-if="$ctrl.movie.directors.length"class="Subheading--small">Director</h4>
                <p ng-if="$ctrl.movie.directors.length"class="Text--small">
                  <span ng-repeat="director in $ctrl.movie.directors">{{ director.name }}<br></span>
                </p>
                <h4 ng-if="$ctrl.movie.writers.length"class="Subheading--small">Writer</h4>
                <p ng-if="$ctrl.movie.writers.length"class="Text--small">
                  <span ng-repeat="writer in $ctrl.movie.writers">{{ writer.name }}<br></span>
                </p>
              </div>
            </div>
            <div class="MoviePopup__actions">
              <button ng-click="$ctrl.hideDetails()" class="MoviePopup__action">Close</button>
              <button ng-click="$ctrl.selectMovie($ctrl.movie)" class="MoviePopup__action--primary">Select</button>
            </div>
          </div>
        </div>
      </div>
    `,

    controller: function(gameData, Movie) {
      var $ctrl = this
      var fullMovie

      $ctrl.$onInit = init
      $ctrl.showDetails = showDetails
      $ctrl.hideDetails = hideDetails
      $ctrl.gameData = gameData

      function init() {
        var season = $ctrl.season = gameData.season
        $ctrl.isTopfive = season.gameType === 'topfive'
        $ctrl.isStudio = season.gameType === 'studio'
        $ctrl.selectMovie = $ctrl.onSelect()
      }

      function showDetails() {
        if (!fullMovie) return getFullMovie()
        $ctrl.showingDetails = true
      }

      function getFullMovie() {
        Movie.get($ctrl.movie.id).then(getFullMovieSuccess)
      }

      function getFullMovieSuccess(movie) {
        $ctrl.movie = fullMovie = movie
        showDetails()
      }

      function getFullMovieError() {
        // TODO
      }

      function hideDetails(e) {
        if (e && e.target !== e.currentTarget) return
        $ctrl.showingDetails = false
      }
    }

  })
