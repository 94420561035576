angular.module('services')
  .factory('Movie', function(_, Service, Model, Time, $filter, $http) {

    const dateFilter = $filter('date')
    const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

    class MovieService extends Service {

      constructor() {
        super()
      }

      url(params) {
        return super.url(params, '/api/movies')
      }

      search(terms) {
        return $http.get(`${this.url()}/search?${$.param({terms:terms})}`).then(this.deserialize)
      }
    }

    class Movie extends Model {

      weekendDisplay(week) {
        week = week - this.releaseWeek + 1
        return week > 0 ? 'Weekend #' + week : ''
      }

      weekendAbbrev(week) {
        week = week - this.releaseWeek + 1
        return week > 0 ? 'W' + week : ''
      }

      get hasOpened() {
        if (!this.releaseWeek && !this.releaseDate) return false
        if (Time.currentWeek > this.releaseWeek) return true
        if (Time.currentWeek < this.releaseWeek) return false
        if (Time.currentDay === 7) return true // all movies relesed this week are open by Sunday
        return Time.currentDay >= this.releaseDay
      }

      get hasNotOpened() {
        return !this.hasOpened
      }

      get releaseDateObj() {
        if (!this.releaseDate) return new Date('invalid')
        var utc = new Date(this.releaseDate)
        return new Date(utc.getUTCFullYear(), utc.getUTCMonth(), utc.getUTCDate(), 0, 0, 0)
      }

      get opensDisplay() {
        if (!this.releaseDate) return 'No set release date'
        var prefix = this.hasOpened ? 'Opened' : 'Opens'
        var date = dateFilter(this.releaseDateObj, 'M/d')
        return prefix + ' ' + date
      }

      get opensDay() {
        if (!this.releaseDate) return ''
        return dateFilter(this.releaseDateObj, 'EEE')
      }

      get releaseDay() {
        return this.releaseDateObj.getDay() || 7 // force sunday to 7 instead of 0
      }

      get releaseStatus() {
        return this.hasOpened ? 'Open' : 'Upcoming'
      }

      get cast() {
        return _.filter(this.roles, { isCast: true })
      }

      get directors() {
        return _.filter(this.roles, { role: 'Director' })
      }

      get writers() {
        return _.filter(this.roles, { role: 'Writer' })
      }

      get trailer() {
        return _.first(this.trailers)
      }

      get backdrop() {
        var url = this.backdropUrl || ''
        return url.match(/null$/) ? '' : url
      }

      get poster() {
        var url = this.posterUrl || ''
        return url.match(/null$/) ? '' : url
      }

    }

    return Service.register('Movie', MovieService, Movie)

  })
