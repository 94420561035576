angular.module('boc', [
  'ngComponentRouter',
  'ngSanitize',
  'services',
  'filters'
])

.config(function($locationProvider) {
  $locationProvider.html5Mode(true)
})

.value('$routerRootComponent', 'app')

.component('app', {
  template: `
    <div>
      <ng-outlet></ng-outlet>
      <chatter></chatter>
    </div>
  `,
  controller: function(_, $rootRouter, User, $rootScope, $window) {
    const $ctrl = this
    $ctrl.user = User.current

    $rootRouter.config(withSeasonAndWeek([
      { path: '/blockbuster/...', name: 'Blockbuster', component: 'game', data: { leagueId: 1 } },
      { path: '/topfive/...', name: 'Topfive', component: 'game', data: { leagueId: 2 } },
      { path: '/leagues/:leagueId/...', name: 'League', component: 'game' }
    ]))

    $rootScope.$on('$routeChangeSuccess', function(ev) {
      $window.ga('send', 'pageview')
    })

    function withSeasonAndWeek(config) {
      return config.concat(
        duplicateConfig(config, '/season/:seasonId', 'Season'),
        duplicateConfig(config, '/season/:seasonId/week/:week', 'Week')
      )
    }

    function duplicateConfig(config, path, name) {
      return _.map(config, function(def) {
        return _.defaults({
          path: def.path.replace('/...', path + '/...'),
          name: def.name + name
        }, def)
      })
    }

  }
})
