angular.module('boc')
  .directive('svgImg', function($log) {
    return {
      restrict:'E',
      scope: {
        class: '@',
        style: '=',
        use: '@'
      },
      link: function($scope, $element) {
        var lastElement = $element
        $scope.$watchGroup(['class', 'use'], function() {
          if (!$scope.use) return $log.error('svg-img `use` attribute cannot be blank')
          var classes = ['svg-' + $scope.use, $scope.class || '']
          var classAttr = ' class="' + classes.join(' ') + '"'
          var styleAttr = $scope.style ? ' style="' + $scope.style + '"' : ''
          var $svg = $('<svg' + classAttr + styleAttr + '><use xlink:href="#svg-' + $scope.use + '"></use></svg>')
          lastElement.replaceWith($svg)
          lastElement = $svg
        })
        $scope.$on('$destroy', function() {
          lastElement.remove()
        })
      }
    }
  })
