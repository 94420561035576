angular.module('boc')
  .component('gamePickDetails', {

    bindings: {
      gameTeamEntry: '=',
      onUpdate: '&',
      onRemove: '&'
    },

    template: `
      <!-- studio -->
      <div ng-if="$ctrl.gameTeamEntry.isStudio && $ctrl.gameTeamEntry.isNotEmpty">
        <div ng-if="$ctrl.gameTeamEntry.isNotLocked" class="MovieCard__release-date">
          {{ $ctrl.gameTeamEntry.movie.opensDisplay }}
          <span class="MovieCard__release-day">({{ $ctrl.gameTeamEntry.movie.opensDay }})</span>
        </div>
        <div ng-if="$ctrl.gameTeamEntry.isLocked" class="MovieCard__earnings">
          <span ng-if="$ctrl.gameTeamEntry.revenue" class="MovieCard__earnings-value">{{ $ctrl.gameTeamEntry.revenueWithBonus | revenue }}</span>
          <span ng-if="!$ctrl.gameTeamEntry.revenue" class="MovieCard__earnings-value">-----</span>
        </div>
        <div ng-if="$ctrl.gameTeamEntry.isLocked" class="MovieCard__secondary-result">
          <span ng-if="$ctrl.gameTeamEntry.revenue">{{ $ctrl.gameTeamEntry.revenueChange | revenue }}</span>
          <span ng-if="!$ctrl.gameTeamEntry.revenue" class="MovieCard__secondary-result-no-data">Data Not Available</span>
        </div>
      </div>
      <!-- topfive -->
      <div ng-if="$ctrl.gameTeamEntry.isTopfive && $ctrl.gameTeamEntry.isNotEmpty">
        <div ng-if="$ctrl.gameTeamEntry.isNotFinal">
          <div ng-if="$ctrl.gameData.season.currentWeek" class="MovieCard__weekend-num">
            {{ $ctrl.gameTeamEntry.movie.weekendDisplay($ctrl.gameData.week) }}
          </div>
          <input
            ng-if="$ctrl.gameTeamEntry.isNotLocked"
            tabindex="1"
            type="dollars"
            placeholder="Enter a Prediction"
            class="MovieCard__enter-prediction"
            ng-model="$ctrl.gameTeamEntry.predictedEarnings"
            ng-change="$ctrl.changed = true"
            ng-blur="$ctrl.update()"
            ng-keypress="$ctrl.updateOnEnter($event)">
          <div ng-if="$ctrl.gameTeamEntry.isLocked" class="MovieCard__earnings">
            <span ng-if="$ctrl.gameTeamEntry.predictedEarnings" class="MovieCard__earnings-value">{{ $ctrl.gameTeamEntry.predictedEarnings | revenue }}</span>
            <span ng-if="!$ctrl.gameTeamEntry.predictedEarnings" class="MovieCard__earnings-value">-----</span>
          </div>
          <div class="MovieCard__secondary-result">
            <span>{{ $ctrl.gameTeamEntry.predictedChange }}</span>
          </div>
        </div>
        <div ng-if="$ctrl.gameTeamEntry.isFinal">
          <div class="MovieCard__earnings">
            <span ng-if="$ctrl.gameTeamEntry.revenue" class="MovieCard__earnings-value">{{ $ctrl.gameTeamEntry.revenueWithBonus | revenue }}</span>
            <span ng-if="!$ctrl.gameTeamEntry.revenue" class="MovieCard__earnings-value">{{ 0 | revenue }}</span>
          </div>
          <div ng-if="$ctrl.gameTeamEntry.isLocked" class="MovieCard__secondary-result">
            <span ng-if="$ctrl.gameTeamEntry.revenue">
              <span class="MovieCard__release-day">max</span>
              {{ ($ctrl.gameTeamEntry.actualEarnings * 1.2) | revenue }}
            </span>
            <span ng-if="!$ctrl.gameTeamEntry.revenue" class="MovieCard__secondary-result-no-data">Data Not Available</span>
          </div>
        </div>
      </div>

      <div ng-if="$ctrl.gameTeamEntry.isRemovable" class="MovieCard__remove-movie">
        <button tabindex="2" class="Link" ng-click="$ctrl.remove($event)">Remove</button>
      </div>

      <!-- Bonus -->

      <div class="MovieCard__bonus" ng-if="$ctrl.gameTeamEntry.isNotEmpty && $ctrl.gameTeamEntry.editedAt">
        <div ng-if="$ctrl.gameTeamEntry.bonusPercent" class="Notification--success Notification--small">
          <p>
            <b>+{{$ctrl.gameTeamEntry.bonusPercent}}% BONUS</b><br>
            <small class="Text--xsmall">{{$ctrl.gameTeamEntry.editedDisplay}}</small>
          </p>
        </div>
        <div ng-if="!$ctrl.gameTeamEntry.bonusPercent" class="Notification--general Notification--small">
          <p>
            <b>NO BONUS</b><br>
            <small class="Text--xsmall">{{$ctrl.gameTeamEntry.editedDisplay}}</small>
          </p>
        </div>
      </div>

      <!-- History/Results -->

      <game-pick-results-topfive
        ng-if="$ctrl.gameTeamEntry.isFinal && $ctrl.gameTeamEntry.isTopfive"
        game-team-entry="$ctrl.gameTeamEntry">
      </game-pick-results-topfive>

      <movie-history
        ng-if="$ctrl.gameTeamEntry.movie.id && $ctrl.gameTeamEntry.isNotLocked"
        movie="$ctrl.gameTeamEntry.movie"
        show-title="true">
      </movie-history>
    `,

    controller: function(gameData) {

      var $ctrl = this

      $ctrl.gameData = gameData
      $ctrl.remove = remove
      $ctrl.update = update
      $ctrl.updateOnEnter = updateOnEnter

      function remove(e) {
        e.stopPropagation()
        $ctrl.gameTeamEntry
          .reset()
          .then($ctrl.onRemove)
      }

      function update() {
        if ($ctrl.changed) {
          $ctrl.changed = false
          $ctrl.onUpdate()
        }
      }

      function updateOnEnter(e) {
        if (e.which === 13) {
          e.stopPropagation()
          update()
        }
      }

    }

  })
