angular.module('services')
  .factory('League', function(_, Service, Model, Time, Season) {

    class LeagueService extends Service {

      constructor() {
        super()
      }

      url(params) {
        return super.url(params, '/api/leagues')
      }
    }

    class League extends Model {

      get associations() {
        return {
          seasons: 'Season'
        }
      }

      get currentSeason() {
        var seasons = this.seasons
        if (!seasons || !seasons.length) return null
        var firstSeason
        var lastSeason
        var currentWeek = Time.currentWeek
        for (var i = 0; i < seasons.length; i++) {
          var season = seasons[i]
          if (currentWeek >= season.startWeek && currentWeek <= season.endWeek) return season
          if (!firstSeason || firstSeason.startWeek > season.startWeek) firstSeason = season
          if (!lastSeason || lastSeason.startWeek < season.startWeek) lastSeason = season
        }
        return currentWeek < firstSeason.startWeek ? firstSeason : lastSeason
      }

      get nextSeason() {
        var seasons = this.seasons
        if (!seasons || !seasons.length) return null
        var nextSeason
        var currentWeek = Time.currentWeek
        for (var i = 0; i < seasons.length; i++) {
          var season = seasons[i]
          if (season.startWeek <= currentWeek) continue
          if (!nextSeason || season.startWeek < nextSeason.startWeek) nextSeason = season
        }
        return nextSeason
      }

      get isTopfive() {
        return this.gameType === 'topfive'
      }

      get isStudio() {
        return this.gameType === 'studio'
      }

    }

    return Service.register('League', LeagueService, League)

  })
