angular.module('boc')
  .component('gameHelp', {

    template: `
      <div ng-if="$ctrl.helpView" ng-include="$ctrl.helpView"></div>
    `,

    controller: function($scope, gameData) {

      var $ctrl = this
      $scope.$watch(league, update)

      function league(){
        return gameData.league
      }

      function update(league) {
        var type = league.gameType
        $ctrl.helpView = type ? `static-views/${type}-help.html` : null
      }
    }

  })
