// Triggers the `on-user-update` callback on enter, blur and after a timeout.

angular.module('boc')
  .directive('onUserUpdate', function($timeout) {

    return {
      restrict: 'A',
      require: 'ngModel',
      link: function($scope, $element, $attrs, ngModel) {
        var lastVal = ngModel.$modelValue

        $element.on('focus', selectAll)
        $element.on('blur', updateSoonIfChanged)
        $element.on('keypress', selectIfEnter)

        function selectAll() {
          $element.select()
        }

        function selectIfEnter(e) {
          if (e.type === 'keypress' && e.which === 13) $element.blur().focus()
        }

        function updateSoonIfChanged() {
          $timeout(updateIfChanged) // timeout allows model value to get set
        }

        function updateIfChanged() {
          var newVal = ngModel.$modelValue
          if (newVal === lastVal) return
          lastVal = newVal
          $scope.$eval($attrs.onUserUpdate)
        }
      }
    }

  })
