angular.module('services')
  .factory('Team', function(Service, Model, $http) {

    const $ = angular.element

    class TeamService extends Service {

      constructor() {
        super()
      }

      url(params) {
        return super.url(params, '/api/teams')
      }

      latest(params) {
        return $http.get(`${this.url()}/latest?${$.param(params)}`).then(this.deserialize)
      }

    }

    class Team extends Model {

    }

    return Service.register('Team', TeamService, Team)

  })
