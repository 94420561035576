angular.module('boc').component('movieHistory', {

  bindings: {
    movie: '<',
    showTitle: '<'
  },
  template: `
    <div class="MovieStats" ng-if="$ctrl.earningsByWeek.length">
      <h4 ng-if="$ctrl.showTitle" class="MovieStats__label">History</h4>
      <ul ng-repeat="earning in $ctrl.earningsByWeek" class="MovieStats__stats">
        <li class="MovieStats__stat--primary MovieStats__stat--small">{{earning.week}}</li>
        <li class="MovieStats__stat--secondary">{{earning.change}}</li>
        <li class="MovieStats__stat--primary">{{earning.revenue}}</li>
      </ul>
    </div>
  `,
  controller: function($filter, Time, util){

    var MAX_ROWS = 3
    var $ctrl = this
    var dateFilter = $filter('date')
    var revenueAbbrevFilter = $filter('revenueAbbrev')

    $ctrl.$onInit = function() {
      $ctrl.earningsByWeek = parseEarnings()
    }

    function parseEarnings() {
      var movie = $ctrl.movie || {}
      var earnings = movie.recentEarnings || []
      var earningsByWeek = []
      var max = Math.min(earnings.length, MAX_ROWS)
      for (var i = 0; i < max; i++) {
        var earning = earnings[i]
        if (!earning.weekend) continue // this earning is likely from before the movie started
        var prev = earnings[i + 1]
        earningsByWeek.push({
          week: movie.weekendAbbrev(earning.week),
          change: percentChange(prev, earning),
          revenue: earning.weekend ? revenueAbbrevFilter(earning.weekend) : ''
        })
      }
      return earningsByWeek
    }

    function percentChange(prev, earning) {
      if (!prev || !prev.weekend) return 'New'
      return util.percentChange(prev.weekend, earning.weekend) + '%'
    }

  }

})
