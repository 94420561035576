angular.module('services')
  .factory('gameData', function(
    _,
    $q,
    $filter,
    League,
    Season,
    Team,
    User,
    Time,
    $location,
    ordinal,
    Movie,
    SeasonMovie,
    GameMovie,
    GameTeam,
    GameTeamEntry,
    $window,
    $timeout,
    $rootRouter,
    util
  ) {

    var $ = angular.element
    var params = {}
    var lastTeamEntries
    var lastWeekWatcher
    var updateListeners = []

    var gameData = {
      changeSeason: changeSeason,
      clearCache: clearCache,
      endUpdate: endUpdate,
      gameRouteUpdate: gameRouteUpdate,
      get: get,
      getGameTeam: getGameTeam,
      getGameTeamEntries: getGameTeamEntries,
      getGameTeams: getGameTeams,
      getLatestTeam: getLatestTeam,
      getMovies: getMovies,
      retry: retry,
      setGameTeam: setGameTeam,
      setGameTeamEntries: setGameTeamEntries,
      setLatestTeam: setLatestTeam,
      startUpdate: startUpdate,
      url: url,
      weekGetterSetter: weekGetterSetter,
      weekWatcher: weekWatcher,
      weekNav: [],

      // GETTERS

      get rankOfTotal() {
        var rank = gameData.get('standingsGameTeam.rank')
        var total = gameData.get('season.teamCount')
        if (!rank || !total) return ''
        return ordinal(rank) + ' of ' + $filter('number')(total)
      },

      get seasonRankOfTotal() {
        var rank = gameData.get('standingsGameTeam.seasonRank')
        var total = gameData.get('season.teamCount')
        if (!rank || !total) return ''
        return ordinal(rank) + ' of ' + $filter('number')(total)
      }
    }

    window.gameData = gameData

    return gameData

    function clearCache() {
      lastTeamEntries = null
    }

    function get(path) {
      return _.get(gameData, path)
    }

    function startUpdate(reload) {
      if (reload) gameData.reloading = reload
      if (gameData.reloading) return
      if (gameData.loaded) scrollToTop()
      gameData.loaded = false
    }

    function endUpdate() {
      gameData.loaded = true
      gameData.reloading = false
    }

    function scrollToTop() {
      var scroll = { x: $window.scrollX, y: $window.scrollY }
      var lastX
      if (!scroll.y && !scroll.x) return
      $(scroll).animate({ x: 0, y: 0 }, {
        duration: 500,
        step: function(val, opts) {
          if (opts.prop === 'x') return lastX = val
          $window.scroll(Math.floor(lastX), Math.floor(val))
        }
      })
    }

    function retry() {
      gameRouteUpdate(gameData.gameInstruction)
    }

    // Load league/season/week based on route and sync url if needed
    function gameRouteUpdate(instruction) {
      gameData.gameInstruction = instruction
      startUpdate()
      return getLeague(routeParam('leagueId'))
        .then(setLeague)
        .then(setSeason)
        .then(syncWeek)
        .then(endUpdate)
        .catch(err('Could not update game route.'))
    }

    // LEAGUE

    function getLeague(leagueId) {
      if (getLeague.id !== leagueId) {
        getLeague.promise = League.get(leagueId)
        getLeague.id = leagueId
      }
      return getLeague.promise
    }

    function setLeague(league) {
      gameData.league = league
      var seasons = league.seasons
      return _.find(seasons, { id: routeParam('seasonId') }) || _.find(seasons, { id: league.currentSeason.id })
    }

    // SEASON & WEEK

    function changeSeason(season) {
      setSeason(season)
      $location.url(url(season.id))
    }

    function setSeason(season) {
      delete gameData.movies
      gameData.season = season
      var seasons = gameData.league.seasons
      var seasonIndex = seasons.indexOf(season)
      gameData.prevSeason = seasonIndex > 0 ? seasons[seasonIndex - 1] : null
      gameData.nextSeason = seasons[seasonIndex + 1] || null
    }

    // updates URL with current season/week
    function weekGetterSetter(week) {
      if (arguments.length) $location.url(url(gameData.season.id, week))
      else return gameData.week
    }

    function syncWeek() {
      var nav = gameData.weekNav
      var week = gameData.week = routeParam('week')
      var season = gameData.season || {}
      var team = gameData.latestTeam || {}
      var noActiveWeek = team.id && !team.activeWeek

      // set max week, defaulting to current season week
      gameData.maxWeek = season.playableWeek
      // adjust the max week if we have a team, and the season is not over
      if (gameData.gameTeamIsUsers && season.currentWeek < season.endWeek) {
        // use team active week if valid
        if (team.activeWeek) gameData.maxWeek = Math.min(team.activeWeek, gameData.maxWeek)
        // otherwise if team has no active week, use the season start week
        else gameData.maxWeek = season.startWeek
      }

      // make sure week is valid
      if (!week || week < season.startWeek || week > gameData.maxWeek) {
        gameData.week = Time.activeWeek = gameData.maxWeek
      }
      // update week nav
      week = season.startWeek
      var numWeeks = (gameData.maxWeek - week + 1) || 0
      nav.length = 0
      for (var i = 0; i < numWeeks; i++) {
        nav[i] = {
          label: season.weekOfTotal(week + i),
          value: week + i
        }
      }

    }

    // TEAM & ENTRIES

    function getLatestTeam() {
      return Team
        .latest({
          leagueId: gameData.league.id,
          userId: User.current.id,
          week: gameData.season && gameData.season.currentWeek || gameData.season.startWeek
        })
        .then(setLatestTeam, clearLatestTeam)
    }

    function setLatestTeam(latestTeam) {
      gameData.latestTeam = latestTeam
      gameData.userHasJoined = !!latestTeam && latestTeam.seasonId === gameData.season.id
      if (gameData.userHasJoined) return syncWeek()
      clearGameTeam()
    }

    function clearLatestTeam() {
      setLatestTeam(null)
      clearGameTeam()
    }

    function clearGameTeam() {
      setGameTeam({ gameTeam: null })
      setGameTeamEntries([])
      lastTeamEntries = getGameTeam.params = null
    }

    function getGameTeam(userId) {
      var season = gameData.season || {}
      var weekHasRevenue = gameData.week <= season.currentWeek
      var currentUserId = User.current && User.current.id || null
      if (!userId) userId = currentUserId
      gameData.gameTeamIsUsers = userId === currentUserId

      var params = {
        seasonId: season.id,
        userId: userId,
        week: gameData.week
      }

      if (getGameTeam.userId !== userId || !_.isEqual(getGameTeam.params, params)) {
        var gt = GameTeam.get(params)
        var sgt = weekHasRevenue || !season.currentWeek ? gt : GameTeam.get(_.defaults({ week: season.currentWeek }, params))
        getGameTeam.promise = $q.all({
          gameTeam: gt,
          standingsGameTeam: sgt
        })
        getGameTeam.params = params
        getGameTeam.userId = userId
      }

      return getGameTeam.promise
        .then(setGameTeam)
    }

    function setGameTeam(resp) {
      var gt = gameData.gameTeam = resp.gameTeam
      var rev = gt ? gt.revenue : null
      var sgt = resp.standingsGameTeam
      gameData.standingsGameTeam = rev === null ? sgt || gt : gt
      // force promise resolution to fix bug after join where last request didn't find a team
      getGameTeam.promise = $q.resolve(resp)
    }

    function getGameTeamEntries() {
      startUpdate()
      var gt = gameData.gameTeam || {}
      if (!gt.id) return $q.resolve([])
      if (lastTeamEntries && gt.id && gt.id === lastTeamEntries.gameTeamId) return lastTeamEntries

      var params = {
        gameTeamId: gt.id,
        seasonId: gameData.season.id,
        teamId: gt.teamId
      }

      // Pass mock time to server to modify the hidden flag
      if (window.mockTime.nowOffset) params.mockNow = window.mockTime.now()

      lastTeamEntries = GameTeamEntry.list({ params: params }).then(setGameTeamEntries)
      lastTeamEntries.gameTeamId = gt.id
      return lastTeamEntries
    }

    function setGameTeamEntries(gameTeamEntries) {
      return gameData.gameTeamEntries = _.map(gameTeamEntries, function(entry) {
        return GameTeamEntry.createInstance(entry)
      })
    }

    // STANDINGS

    function getGameTeams(params) {
      var season = gameData.season
      if (!season) return $q.resolve([])

      params = _.defaults({
        week: season.currentWeek || season.startWeek,
        seasonId: season.id
      }, params)

      if (_.isEqual(getGameTeams.lastParams, params)) return getGameTeams.promise
      getGameTeams.lastParams = angular.copy(params)

      return getGameTeams.promise = GameTeam.list({ params: params }).then(setGameTeams)
    }

    function setGameTeams(teams) {
      gameData.gameTeams = teams
    }

    // MOVIES

    function getMovies() {
      if (!gameData.season || !gameData.week) return $q.resolve([])
      return gameData.movies = gameData.movies || getMoviesByGameType()
    }

    function getMoviesByGameType() {
      if (gameData.league.isStudio) return SeasonMovie.available(gameData.season.id, gameData.week)
      if (gameData.league.isTopfive) return getGameMovies()
      return $q.resolve([])
    }

    function getGameMovies() {
      var params = { seasonId: gameData.season.id, week: gameData.week }
      return GameMovie.list({ params: params}).then(parseGameMovies)
    }

    function parseGameMovies(gameMovies) {
      return _.map(gameMovies, function(gm){
        gm.movie.status = gm.status
        return Movie.createInstance(gm.movie)
      })
    }

    // UTILITIES

    function url(seasonId, week) {
      // URL: `/topfive/play`
      //      `/topfive/season/:seasonId/week/:week/play`
      //      `/leagues/:leagueId/play`
      //      `/leagues/:leagueId/season/:seasonId/week/:week/play`
      var url = $location.url()
      var replaceWeek = week ? '$1/week/' + week : '$1'
      url = url.replace(/(topfive|blockbuster|leagues\/\d+)(\/season\/\d+)*/, '$1/season/' + seasonId)
      url = url.replace(/(\/season\/\d+)(\/week\/\d+)*/, replaceWeek)
      return url
    }

    function routeParam(param) {
      var p = gameData.get('gameInstruction.params.' + param) ||
              gameData.get('gameInstruction.routeData.data.' + param)
      return parseInt(p, 10) || null
    }

    function weekWatcher() {
      var seasonId = _.get(gameData, 'season.id')
      var week = gameData.week
      if (!gameData.loaded || !seasonId || !week) return lastWeekWatcher
      return lastWeekWatcher = seasonId + ':' + week + ':' + gameData.userHasJoined
    }

    function err(message) {
      return function() {
        endUpdate()
        // TODO: show errors somehow in the UI
      }
    }

  })
