angular.module('services')
  .factory('Season', function(_, $http, $filter, $injector, Service, Model, Time, GameTeamEntry, $rootRouter, $location) {

    class SeasonService extends Service {

      constructor() {
        super()
      }

      url(params) {
        return super.url(params, '/api/seasons')
      }

    }

    class Season extends Model {

      get associations() {
        return {
          league: 'League'
        }
      }

      constructor(attrs) {
        super(attrs)
        this.defineNestedProperty('gameType', 'league.gameType')
      }

      get gameData() {
        return $injector.get('gameData')
      }

      get startDate() {
        var filter = this.gameType === 'studio' ? 'weekToMonday' : 'weekToFriday'
        return $filter(filter)(this.startWeek, 'MM/DD/YY') || null
      }

      get endDate() {
        var filter = this.gameType === 'studio' ? 'weekToMonday' : 'weekToFriday'
        return $filter(filter)(this.endWeek, 'MM/DD/YY') || null
      }

      get hasStarted() {
        return this.startWeek <= Time.currentWeek
      }

      get hasNotStarted() {
        return !this.hasStarted
      }

      get isOver() {
        return Time.currentWeek > this.endWeek
      }

      get isNotOver() {
        return !this.isOver
      }

      get playableWeek() {
        var league = this.league || {}
        if (league.isStudio) return this.currentWeek || this.startWeek
        var minWeek = this.currentWeek ? this.currentWeek + 1 : this.startWeek
        return Math.min(minWeek, this.endWeek)
      }

      getTeamCount() {
        var season = this
        var url = SeasonService.prototype.url(season) + '/team_count'
        return $http.get(url).then(function(res) {
          season.teamCount = res.data
        })
      }

      weekOfTotal(week) {
        var total = this.endWeek - this.startWeek + 1
        return `${this.weekNum(week)} of ${total}`
      }

      weekNum(week) {
        var num = (week || Time.activeWeek) - this.startWeek + 1
        return `Week ${num}`
      }

      wNum(week) {
        var num = (week || Time.activeWeek) - this.startWeek + 1
        return `W${num}`
      }

      defaultGameTeamEntries(week) {
        var season = this
        function attrs(customAttrs) {
          return _.extend({ season: season, week: week }, customAttrs)
        }
        switch (this.gameType) {
          case 'topfive': return makeEntries(5, attrs())
          case 'studio': return [].concat(makeEntries(10, attrs()), makeEntries(10, attrs({ cheapSeat: true })))
        }
      }

    }

    function dateToWeek(date) {
      var parts = date.split('/')
      if (parts.length !== 3) return null
      if (parts[2].length == 2) parts[2] = '20' + parts[2]
      var m = parseInt(parts[0], 10) - 1
      var d = parseInt(parts[1], 10)
      var y = parseInt(parts[2], 10)
      return Time.dateToWeek(new Date(y, m, d))
    }

    function isNumLike(str) {
      return str == Number(str)
    }

    function makeEntries(count, defaults) {
      return _.map(Array(count), function(x, i) {
        return _.defaults({ rank: i + 1 }, defaults)
      })
    }

    var seasonApi = Service.register('Season', SeasonService, Season)

    return seasonApi

  })
