angular.module('boc').component('gamePickResults', {

  bindings: {
    gameTeamEntry: '<',
    onClose: '&'
  },

  template: `
    <div class="MoviePopup__overlay">
      <div class="MoviePopup">
        <div class="MoviePopup__content">
          <h3 class="MoviePopup__edit-title">{{ $ctrl.gameTeamEntry.movie.title }}</h3>
          <div ng-if="$ctrl.gameTeamEntry.movie.trailer" class="MoviePopup__trailer">
            <div class="MoviePopup__trailer-video">
              {{ $ctrl.gameTeamEntry.movie.trailer }}
            </div>
          </div>
          <div ng-if="!$ctrl.gameTeamEntry.movie.trailer && $ctrl.gameTeamEntry.movie.backdrop" class="MoviePopup__trailer">
            <div class="MoviePopup__trailer-image" style="background-image: url('{{$ctrl.gameTeamEntry.movie.backdrop}}');"></div>
          </div>
          <game-pick-details
            class="MoviePopup__edit-form"
            game-team-entry="$ctrl.gameTeamEntry">
          </game-pick-details>
          <div class="MoviePopup__actions">
            <button type="button" ng-click="$ctrl.close($event)" class="MoviePopup__action--primary">Close</button>
          </div>
        </div>
      </div>
    </div>
  `,

  controller: function() {

    var $ctrl = this

    $ctrl.close = function(e) {
      e.stopPropagation()
      $ctrl.onClose()
    }
  }

})
