angular.module('boc').component('gamePickEdit', {

  bindings: {
    gameTeamEntry: '=',
    onUpdate: '&',
    onRemove: '&',
    onClose: '&'
  },
  template: `
    <div class="MoviePopup__overlay">
      <div class="MoviePopup">
        <div class="MoviePopup__content">
          <h3 class="MoviePopup__edit-title">{{ $ctrl.movie.title }}</h3>
          <div ng-if="$ctrl.movie.trailer" class="MoviePopup__trailer">
            <div class="MoviePopup__trailer-video">
              {{ $ctrl.movie.trailer }}
            </div>
          </div>
          <div ng-if="!$ctrl.movie.trailer && $ctrl.movie.backdrop" class="MoviePopup__trailer">
            <div class="MoviePopup__trailer-image" style="background-image: url('{{$ctrl.movie.backdrop}}');"></div>
          </div>
          <game-pick-details
            class="MoviePopup__edit-form"
            game-team-entry="$ctrl.gameTeamEntry"
            on-update="update()"
            on-remove="$ctrl.remove()">
          </game-pick-details>
          <div class="MoviePopup__actions">
            <button type="button" ng-click="$ctrl.close($event)" class="MoviePopup__action">Close</button>
            <button type="submit" ng-click="$ctrl.update($event)" class="MoviePopup__action--primary">Save</button>
          </div>
        </div>
      </div>
    </div>
  `,
  controller: function() {

    var $ctrl = this
    var entry
    var originalEarnings

    $ctrl.$onInit = init
    $ctrl.remove = remove
    $ctrl.close = close
    $ctrl.update = update

    function init() {
      entry = $ctrl.gameTeamEntry
      originalEarnings = entry.predictedEarnings
      $ctrl.movie = entry.movie
    }

    function remove() {
      $ctrl.onRemove()
      $ctrl.onClose()
    }

    function update(e) {
      if (e) e.stopPropagation()
      $ctrl.onUpdate().then($ctrl.onClose)
    }

    function close(e) {
      e.stopPropagation()
      if (entry) entry.predictedEarnings = originalEarnings
      $ctrl.onClose()
    }

  }

})
