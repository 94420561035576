angular.module('boc')
  .factory('Chatter', function($http) {
    return {
      flag(id) {
        return $http.put(`/api/chatter/${id}/flag`)
      },
      post(key, message) {
        return $http.post(`/api/chatter/${key}`, {message})
      },
      list(key) {
        return $http.get(`/api/chatter/${key}`)
          .then(r => r.data)
      },
      markViewed(key) {
        return $http.put(`/api/chatter/${key}`)
      }
    }
  })
