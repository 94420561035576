angular.module('boc')
  .component('gamePickResultsTopfive', {

    bindings: {
      gameTeamEntry: '<'
    },

    template: `

      <dl class="ResultData--highlight">
        <dt class="ResultData__label">Prediction</dt>
        <dd class="ResultData__value">{{ $ctrl.gameTeamEntry.predictedEarnings | revenue }}</dd>
        <dt class="ResultData__label">Actual</dt>
        <dd class="ResultData__value">{{ $ctrl.gameTeamEntry.actualEarnings | revenue }}</dd>
        <dt class="ResultData__label">Difference</dt>
        <dd class="ResultData__value">{{ $ctrl.gameTeamEntry.earningsGap | revenue }}</dd>
      </dl>

      <dl class="ResultData">
        <dt class="ResultData__label">Actual - Difference</dt>
        <dd class="ResultData__value">{{ ($ctrl.gameTeamEntry.actualEarnings - $ctrl.gameTeamEntry.earningsGap) | revenue }}</dd>
        <dt class="ResultData__label" ng-bind="$ctrl.gameTeamEntry.bonusLabel"></dt>
        <dd class="ResultData__value" ng-bind="$ctrl.gameTeamEntry.bonusDisplay"></dd>
      </dl>

      <dl class="ResultData--total">
        <dt class="ResultData__label">Total</dt>
        <dd class="ResultData__value">{{ $ctrl.gameTeamEntry.revenueWithBonus | revenue }}</dd>
      </dl>

      <table ng-if="NOTAPPROVED" class="ResultTable">
        <tfoot>
          <tr>
            <th>Total</th>
            <td>{{ $ctrl.gameTeamEntry.revenueWithBonus | revenue }}</td>
          </tr>
        </tfoot>
        <tbody>
          <tr>
            <th>Prediction</th>
            <td>{{ $ctrl.gameTeamEntry.predictedEarnings | revenue }}</td>
          </tr>
          <tr>
            <th>Actual</th>
            <td>{{ $ctrl.gameTeamEntry.actualEarnings | revenue }}</td>
          </tr>
          <tr>
            <th>Off By {{ ($ctrl.gameTeamEntry.earningsGap / $ctrl.gameTeamEntry.actualEarnings) | percent:1 }}</th>
            <td>{{ ($ctrl.gameTeamEntry.actualEarnings - $ctrl.gameTeamEntry.earningsGap) | revenue }}</td>
          </tr>
          <tr>
            <th>{{ $ctrl.gameTeamEntry.bonusLabel }}</th>
            <td>{{ $ctrl.gameTeamEntry.bonus | revenue }}</td>
          </tr>
        </tbody>
      </table>
    `,

    controller: function() {}

  })
