angular.module('boc')
  .component('gameChatter', {

    template: `
      <h2>Champs Chatter</h2>
      <topfive-chatter ng-if="$ctrl.league.gameType === 'topfive'">top five chatter</topfive-chatter>
      <studio-chatter ng-if="$ctrl.league.gameType === 'studio'">studio chatter</studio-chatter>
    `,

    controller: function($scope, League) {
      League.setCurrentOnScopeCtrl($scope, 'league')
    }

  })
