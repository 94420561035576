angular.module('services')
  .factory('User', function(Service, Model, currentUserData) {

    class UserService extends Service {

      constructor() {
        super()
        this.current = currentUserData
      }

      url(params) {
        return super.url(params, '/api/users')
      }
    }

    class User extends Model {

    }

    return Service.register('User', UserService, User)

  })
