angular.module('services')
  .factory('Game', function(Service, Model) {

    class GameService extends Service {

      constructor() {
        super()
      }

      url(params) {
        return super.url(params, '/api/games')
      }
    }

    class Game extends Model {

    }

    return Service.register('Game', GameService, Game)

  })
