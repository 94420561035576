;(function() {

  window.fbox = {
    config: setConfig,
    parseElement: parseElement
  };

  var config = {
    contentMin: 100,
    contentStep: 20,
    contentAttr: 'grid-el'
  };

  function setConfig(opts) {
    for (var key in config) {
      if (key in opts) config[key] = opts[key];
    }
  }

  function resizeGrid() {
    $('[' + config.contentAttr + ']').each(function(i, el) {
      parseElement(el);
    });
  }

  function parseElement(el) {
    var width = config.contentMin;
    var attr = '';
    while (width < el.clientWidth) {
      attr += '>' + (width) + ' ';
      width += config.contentStep;
    }
    $(el).attr(config.contentAttr, attr);
  }

  document.addEventListener("DOMContentLoaded", function(event) {
    resizeGrid();
    window.addEventListener('resize', resizeGrid, false);
  });

})();
