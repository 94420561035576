angular.module('boc')
  .component('gamePlay', {

    template: `
      <div grid="loose">
        <loader grid="1/1@phone" success="$ctrl.gameData.loaded" failure="$ctrl.failure" on-retry="$ctrl.gameData.retry()">
          <game-join ng-if="$ctrl.showJoin" on-join="$ctrl.userJustJoined()"></game-join>
          <game-over ng-if="$ctrl.showGameOver"></game-over>
          <div ng-if="$ctrl.gameData.loaded && !$ctrl.showJoin && !$ctrl.showGameOver" grid="loose">
            <div grid-el grid="1/1@phone">
              <div class="TeamHeader">
                <span ng-if="!$ctrl.gameData.gameTeam.avatar" class="TeamHeader__avatar" alt="{{ $ctrl.gameData.gameTeam.abbrev }}"></span>
                <span ng-if="$ctrl.gameData.gameTeam.avatar" class="TeamHeader__avatar" style="background-image:url({{ $ctrl.gameData.gameTeam.avatar }});"></span>
                <span class="TeamHeader__name">{{ $ctrl.gameData.gameTeam.name }}</span>
              </div>
            </div>
            <div ng-if="$ctrl.nextLink" grid-el grid="1/1@phone">
              <button class="Button--primary-notification" ng-click="$ctrl.nextLink.action()">
                {{$ctrl.nextLink.label}}
              </button>
            </div>
            <div grid-el grid="1/1@phone">
              <div class="Summary">
                <dl class="Summary__item--primary">
                  <dt class="Summary__item-label">Season Total</dt>
                  <dd class="Summary__item-data--large">{{ $ctrl.gameData.standingsGameTeam.revenue | revenue }}</dd>
                  <dd class="Summary__item-info">{{ $ctrl.gameData.seasonRankOfTotal }}</dd>
                </dl>
                <dl class="Summary__item--alternate">
                  <dt class="Summary__item-label">
                    <select
                      class="TextSelect--primary"
                      ng-model="$ctrl.gameData.weekGetterSetter"
                      ng-model-options="{getterSetter:true}"
                      ng-options="week.value as week.label for week in $ctrl.gameData.weekNav">
                    </select>
                    </dt>
                  <dd class="Summary__item-data--large">{{ $ctrl.gameData.standingsGameTeam.revenueChange | revenue }}</dd>
                  <dd class="Summary__item-info">{{ $ctrl.gameData.rankOfTotal }}</dd>
                </dl>
              </div>
            </div>
            <game-bonus-topfive grid-el grid="1/1@phone"
              ng-if="$ctrl.gameData.league.isTopfive && $ctrl.gameData.gameTeamIsUsers">
            </game-bonus-topfive>
            <div grid-el grid="1/1@phone">
              <div grid="{{ $ctrl.atMedia.phone ? '' : 'tight' }} {{ $ctrl.atMedia.desktop ? 'align-center' : '' }} fixed">
                <game-pick
                  grid-el
                  grid="1/1@phone 1/3@tablet 1/5@desktop"
                  ng-repeat="gameTeamEntry in $ctrl.gameData.gameTeamEntries"
                  game-team-entry="gameTeamEntry"
                  game-type="$ctrl.gameData.league.gameType"
                  season="$ctrl.season"
                  on-update="$ctrl.sortEntries()">
                </game-pick>
              </div>
            </div>
            <main class="PageSection">
              <div grid="loose tight@phone">
                <div grid-el=">100 >120 >140 >160 >180 >200 >220 >240 >260 >280 >300 >320 >340 >360 >380 >400 >420 >440 >460 >480 " grid="1/1@phone 1/2@tablet">
                  <a href="http://www.the-numbers.com" target="_blank" style="display: block; width: 15.5rem; margin: auto;"><img src="https://boxofficechamps.com/img/powered-by-the-numbers-game.svg"></a>
                </div>
              </div>
            </main>
          </div>
        </loader>
      </div>
    `,

    controller: function(_, $q, $scope, League, Season, User, Team, GameTeamEntry, Time, gameData, atMedia, $location) {

      var $ctrl = this
      var gd = gameData
      var userMustJoin
      var otherUser
      var userIdParam

      $ctrl.atMedia = atMedia
      $ctrl.gameData = gd
      $ctrl.userJustJoined = userJustJoined
      $ctrl.sortEntries = sortEntries
      $ctrl.$routerOnActivate = $ctrl.$routerOnReuse = load
      $scope.$on('dayUpdate', dayUpdate)

      function dayUpdate() {
        if (otherUser) {
          gameData.clearCache()
          load()
        } else {
          setJoinedState()
          setNextLink()
        }
      }

      function load(route) {
        var myId = User.current.id
        userIdParam = route ? route.params.userId : userIdParam
        otherUser = userIdParam && (userIdParam !== myId)
        startLoad(!route) // pass true for reload
        return otherUser ? loadOtherData(userIdParam) : loadLatestTeam()
      }

      function loadOtherData(userId) {
        return loadData(userId)
          .then(setJoinedState)
          .then(loadSuccess, loadError)
          .finally(gd.endUpdate)
      }

      function loadLatestTeam() {
        return gd.getLatestTeam()
          .then(loadLatestTeamData)
          .catch(loadLatestTeamData)
          .finally(gd.endUpdate)
      }

      function loadLatestTeamData(latestTeam) {
        return $q.when(setJoinedState())
          .then(setWeekAndLoadData)
          .then(loadSuccess, loadError)
      }

      function setWeekAndLoadData() {
        if (!gd.userHasJoined) return
        // if (!gd.week || gd.maxWeek < gd.week) gd.week = gd.maxWeek
        return loadData(User.current.id)
      }

      function startLoad(reload) {
        gd.startUpdate(reload)
        if (reload) return
        $ctrl.failure = $ctrl.showJoin = $ctrl.showGameOver = null
      }

      function loadData(userId) {
        return $q.all([
          gd.season.getTeamCount(),
          gd.getGameTeam(userId).then(gd.getGameTeamEntries)
        ])
      }

      function loadSuccess() {
        $ctrl.activated = true
        setNextLink()
      }

      function setNextLink() {
        if (!gd.gameTeamIsUsers) return $ctrl.nextLink = null

        var team = gd.latestTeam || {}
        var league = gd.league
        var season = gd.season || {}
        var topfive = league.isTopfive
        var playableWeek = season.playableWeek
        var notCurrent = team.activeWeek !== playableWeek && playableWeek !== season.startWeek
        // TODO: the first week for topfive is messed up, because its always set as the current week :(
        var currentSeason = league.currentSeason
        var nextSeason = league.nextSeason

        if (topfive && notCurrent && season.isNotOver) {
          $ctrl.nextLink = {
            action: gotoCurrentWeek,
            label: season.weekNum(season.playableWeek) + " is Open. Play Now!"
          }
        } else if (nextSeason && season !== nextSeason) {
          $ctrl.nextLink = {
            action: gotoNextSeason,
            label: "The Next Season is Open. Play Now!"
          }
        } else {
          $ctrl.nextLink = null
        }
      }

      function gotoCurrentWeek() {
        gd.startUpdate()
        gd.latestTeam.activeWeek = gd.season.playableWeek
        gd.latestTeam.save()
          .then(function(savedTeam) {
            if (savedTeam.activeWeek) gameData.week = savedTeam.activeWeek
            gameData.setLatestTeam(savedTeam)
          })
          .then(setJoinedState)
          .then(setWeekAndLoadData)
          .then(loadSuccess, loadError)
          .finally(gd.endUpdate)
      }

      function gotoNextSeason() {
        gd.changeSeason(gd.league.nextSeason)
      }

      function loadError() {
        $ctrl.failure = true // TODO: add error messages
        $ctrl.activated = true
      }

      function setJoinedState() {
        var mustJoin = !otherUser && !gd.userHasJoined
        $ctrl.showJoin = mustJoin && gd.season.endWeek >= Time.currentWeek
        $ctrl.showGameOver = mustJoin && gd.season.endWeek < Time.currentWeek
      }

      function userJustJoined() {
        startLoad()
        loadLatestTeam()
      }

      function sortEntries() {
        if ($ctrl.gameData.league.isTopfive) {
          gd.gameTeamEntries = gd.gameTeamEntries.sort(highestPrediction)
          _.each(gd.gameTeamEntries, function(entry, i) {
            entry.rank = i + 1
          })
        }
      }

      function highestPrediction(a, b) {
        if (a.movieId && !b.movieId) return -1
        if (b.movieId && !a.movieId) return 1
        if (a.predictedEarnings > b.predictedEarnings || a.predictedEarnings && !b.predictedEarnings) return -1
        if (b.predictedEarnings > a.predictedEarnings || b.predictedEarnings && !a.predictedEarnings) return 1
        if (a.rank > b.rank) return -1
        if (b.rank > a.rank) return 1
        return 0
      }
    }
  })
