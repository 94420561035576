angular.module('boc')
  .component('gamePick', {

    bindings: {
      gameTeamEntry: '=',
      gameType: '<',
      season: '=',
      onUpdate: '&'
    },

    template: `
      <!-- mobile view -->

      <div ng-if="$ctrl.atMedia.phone" ng-click="$ctrl.action()" class="MovieRow{{$ctrl.classSuffix}}">
        <div ng-if="$ctrl.gameTeamEntry.isTopfive" class="MovieRow__cap">
          <svg-img ng-if="$ctrl.gameTeamEntry.isEmpty && $ctrl.gameTeamEntry.isNotLocked" class="Icon" use="warning"></svg-img>
          <svg-img ng-if="$ctrl.gameTeamEntry.isEmpty && $ctrl.gameTeamEntry.isLocked" class="Icon" use="lock"></svg-img>
          <span ng-if="$ctrl.gameTeamEntry.isNotEmpty" class="MovieRow__rank" ng-bind="$ctrl.gameTeamEntry.rankDisplay"></span>
        </div>
        <div ng-if="$ctrl.gameTeamEntry.isStudio" class="MovieRow__cap">
          <span class="MovieRow__rank" ng-bind="$ctrl.gameTeamEntry.rankDisplay"></span>
          <span ng-if="$ctrl.gameTeamEntry.seasonRankDisplay" class="MovieRow__rank-actual">
            ({{ $ctrl.gameTeamEntry.seasonRankDisplay }})
          </span>
        </div>
        <div ng-if="$ctrl.gameTeamEntry.hidden" class="MovieRow__pick">
          <span class="MovieRow__pick-message">Selection Hidden Until Release</span>
        </div>

        <!-- topfive -->
        <div ng-if="$ctrl.gameTeamEntry.isTopfive && $ctrl.gameTeamEntry.isEmpty && $ctrl.gameTeamEntry.isNotFinal" class="MovieRow__pick">
          <span ng-if="$ctrl.gameTeamEntry.isLocked" class="MovieRow__pick-message">No Prediction</span>
          <span ng-if="$ctrl.gameTeamEntry.isNotLocked" class="MovieRow__pick-link">Choose a Movie for This Spot</span>
        </div>
        <div ng-if="$ctrl.gameTeamEntry.isTopfive && $ctrl.gameTeamEntry.isEmpty && $ctrl.gameTeamEntry.isFinal" class="MovieRow__pick">
          <span ng-if="$ctrl.gameTeamEntry.isLocked" class="MovieRow__pick-message">No Movie Selected</span>
          <span ng-if="$ctrl.gameTeamEntry.isNotLocked" class="MovieRow__pick-link">Choose a Movie for This Spot</span>
        </div>
        <div ng-if="$ctrl.gameTeamEntry.isTopfive && $ctrl.gameTeamEntry.isNotEmpty" class="MovieRow__pick">
          <div class="MovieRow__movie-title">{{ $ctrl.gameTeamEntry.movie.title }}</div>
          <div ng-if="!$ctrl.gameTeamEntry.predictedEarnings && $ctrl.gameTeamEntry.isNotFinal" class="MovieRow__release">
            No Prediction
          </div>
          <div ng-if="!$ctrl.gameTeamEntry.predictedEarnings && $ctrl.gameTeamEntry.isFinal" class="MovieRow__earnings">
            {{ 0 | revenue }}
          </div>
          <div ng-if="$ctrl.gameTeamEntry.predictedEarnings" class="MovieRow__earnings">
            <span ng-if="$ctrl.gameTeamEntry.isNotFinal">{{ $ctrl.gameTeamEntry.predictedEarnings | revenue }}</span>
            <span ng-if="$ctrl.gameTeamEntry.isFinal">{{ $ctrl.gameTeamEntry.revenue | revenue }}</span>
          </div>
        </div>

        <!-- blockbuster -->
        <div ng-if="$ctrl.gameTeamEntry.isStudio && $ctrl.gameTeamEntry.isEmpty" class="MovieRow__pick">
          <span ng-if="$ctrl.gameTeamEntry.isLocked" class="MovieRow__pick-message">No Movie Selected</span>
          <span ng-if="$ctrl.gameTeamEntry.isNotLocked" class="MovieRow__pick-link">Choose a Movie for This Spot</span>
        </div>
        <div ng-if="$ctrl.gameTeamEntry.isStudio && $ctrl.gameTeamEntry.isNotEmpty" class="MovieRow__pick">
          <div class="MovieRow__movie-title">{{ $ctrl.gameTeamEntry.movie.title }}</div>
          <div class="MovieRow__earnings">
            <span ng-if="$ctrl.gameTeamEntry.movie.hasNotOpened" class="MovieRow__release">
              {{ $ctrl.gameTeamEntry.movie.opensDisplay }} <span>({{ $ctrl.gameTeamEntry.movie.opensDay }})</span>
            </span>
            <span ng-if="$ctrl.gameTeamEntry.movie.hasOpened && !$ctrl.gameTeamEntry.revenue" class="MovieRow__release">
              Data Not Available
            </span>
            <span ng-if="$ctrl.gameTeamEntry.movie.hasOpened && $ctrl.gameTeamEntry.revenue" class="MovieRow__earnings-value">
              {{ $ctrl.gameTeamEntry.revenueWithBonus | revenue }}
            </span>
          </div>
        </div>

        <div ng-if="$ctrl.gameTeamEntry.isEditable" class="MovieRow__icon">
          <svg-img class="Icon" use="pencil"></svg-img>
        </div>
        <div ng-if="$ctrl.gameTeamEntry.isNotEditable && $ctrl.gameTeamEntry.isRemovable" class="MovieRow__icon" ng-click="$ctrl.removeMovie($event)">
          <svg-img class="Icon--alert" use="times"></svg-img>
        </div>
        <div ng-if="$ctrl.gameTeamEntry.isFinal && $ctrl.gameTeamEntry.isNotEmpty" class="MovieRow__icon">
          <svg-img class="Icon" use="info-circle"></svg-img>
        </div>
        <game-pick-edit
          ng-if="$ctrl.editMode"
          ng-click="$event.stopPropagation()"
          class="MovieCard{{$ctrl.classSuffix}}"
          game-team-entry="$ctrl.gameTeamEntry"
          on-remove="$ctrl.removeMovie()"
          on-update="$ctrl.saveEntry()"
          on-close="$ctrl.editMode = false">
        </game-pick-edit>
        <game-pick-results
          ng-if="$ctrl.showResults"
          game-team-entry="$ctrl.gameTeamEntry"
          on-close="$ctrl.showResults = false">
        </game-pick-results>
      </div>

      <!-- Desktop view -->

      <div ng-if="!$ctrl.atMedia.phone" ng-click="$ctrl.action()" class="MovieCard{{$ctrl.classSuffix}}">
        <header ng-if="$ctrl.gameTeamEntry.isTopfive" class="MovieCard__cap">
          <svg-img ng-if="$ctrl.gameTeamEntry.isEmpty && $ctrl.gameTeamEntry.isNotLocked" class="Icon" use="warning"></svg-img>
          <svg-img ng-if="$ctrl.gameTeamEntry.isEmpty && $ctrl.gameTeamEntry.isLocked" class="Icon" use="lock"></svg-img>
          <span ng-if="$ctrl.gameTeamEntry.isNotEmpty" class="MovieCard__rank" ng-bind="$ctrl.gameTeamEntry.rankDisplay"></span>
        </header>
        <header ng-if="$ctrl.gameTeamEntry.isStudio" class="MovieCard__cap">
          <span class="MovieCard__rank" ng-bind="$ctrl.gameTeamEntry.rankDisplay"></span>
          <span ng-if="$ctrl.gameTeamEntry.seasonRankDisplay" class="MovieCard__rank-actual">
            ({{ $ctrl.gameTeamEntry.seasonRankDisplay }})
          </span>
        </header>
        <div ng-if="$ctrl.gameTeamEntry.hidden" class="MovieCard__pick">
          <span class="MovieCard__pick-message">Selection Hidden <keep-together>Until Release</keep-together></span>
        </div>
        <div ng-if="$ctrl.gameTeamEntry.isEmpty" class="MovieCard__pick">
          <span ng-if="$ctrl.gameTeamEntry.isLocked" class="MovieCard__pick-message">No Movie Selected</span>
          <span ng-if="$ctrl.gameTeamEntry.isNotLocked" class="MovieCard__pick-link">Choose a Movie <keep-together>for This Spot</keep-together></span>
        </div>
        <div ng-if="$ctrl.gameTeamEntry.isNotEmpty" class="MovieCard__pick">
          <img
            ng-if="$ctrl.gameTeamEntry.movie.poster"
            class="MovieCard__pick-image"
            alt="{{ $ctrl.gameTeamEntry.movie.title }}"
            ng-src="{{ $ctrl.gameTeamEntry.movie.poster }}"
          />
          <div
            ng-if="!$ctrl.gameTeamEntry.movie.poster"
            class="MovieCard__pick-image"
            alt="{{ $ctrl.gameTeamEntry.movie.title }}">
          </div>
        </div>
        <!-- hidden -->
        <div ng-if="$ctrl.gameTeamEntry.hidden" class="MovieCard__earnings">&nbsp;</div>
        <div ng-if="$ctrl.gameTeamEntry.hidden" class="MovieCard__secondary-result">&nbsp;</div>
        <!-- details -->
        <game-pick-details
          ng-if="!$ctrl.gameTeamEntry.hidden"
          game-team-entry="$ctrl.gameTeamEntry"
          on-remove="$ctrl.removeMovie()"
          on-update="$ctrl.saveEntry()">
        </game-pick-details>
      </div>

      <!-- Movie select -->

      <game-select-movie
        ng-if="$ctrl.showMovies"
        on-select="$ctrl.setMovie">
      </game-select-movie>
    `,

    controller: function($scope, $element, $q, $timeout, Movie, setAs, atMedia, gameData) {
      var $ctrl = this
      var entry
      var lastPrediction

      $ctrl.atMedia = atMedia
      $ctrl.action = action
      $ctrl.cancelEditing = cancelEditing
      $ctrl.doneEditing = doneEditing
      $ctrl.setMovie = setMovie
      $ctrl.saveEntry = saveEntry
      $ctrl.removeMovie = removeMovie
      $scope.$watch('$ctrl.gameTeamEntry.predictedEarnings', updateDisplay)
      $scope.$on('dayUpdate', updateDisplay)

      $ctrl.$onInit = function() {
        entry = $ctrl.gameTeamEntry
      }

      $ctrl.submit = function submit(event) {
        doneEditing(event)
      }

      function edit() {
        lastPrediction = entry.predictedEarnings
        $ctrl.editMode = true
      }

      function cancelEditing(event) {
        entry.predictedEarnings = lastPrediction
        doneEditing(event)
      }

      function doneEditing(event) {
        if (event) event.stopPropagation()
        $ctrl.editMode = false
      }

      function updateDisplay() {
        var movie = entry.movie
        var status = entry.status
        var editable = status === 'editable' || status === 'empty'
        if (!editable) $ctrl.showMovies = false
        $ctrl.classSuffix = classSuffix()
        $ctrl.weekendDisplay = $ctrl.gameType === 'topfive' && movie && entry.isNotFinal ? movie.weekendDisplay(entry.week) : ''
      }

      function action() {
        var phone = $ctrl.atMedia.phone
        if (entry.isFinal && entry.isNotEmpty && phone) return $ctrl.showResults = true
        if (entry.isLocked) return
        if (entry.isEmpty) selectMovie()
        else if (entry.isEditable) {
          if (phone) edit()
          $timeout(setFocus)
        }
      }

      function classSuffix() {
        if (entry.status === 'date-alert') return '--date-alert'
        var suffix = entry.isCheapSeat ? '--cheap-seat' : ''
        if (entry.status) suffix += ' is-' + entry.status
        return suffix
      }

      function saveEntry() {
        return entry.save().then(function() {
          $ctrl.onUpdate()
          $ctrl.editMode = false
        })
      }

      function selectMovie() {
        $ctrl.showMovies = true
      }

      function setMovie(movie) {
        if (!movie) {
          $ctrl.showMovies = false
          return $q.resolve()
        }
        return entry
          .reset({ movieId: movie.id, movie: movie })
          .then(setMovieSuccess, setMovieError)
      }

      function setMovieSuccess() {
        $ctrl.onUpdate()
        updateDisplay()
        if ($ctrl.atMedia.phone && entry.isEditable) edit()
        $timeout(setFocus)
      }

      function setMovieError() {
        // TODO: handle error
      }

      function setFocus() {
        $element.find('input').focus()
      }

      function removeMovie($event) {
        if ($event) $event.stopPropagation()
        entry.reset({
          movieId: null,
          movie: null
        }).then(function() {
          updateDisplay()
          $ctrl.onUpdate(entry)
        })
      }
    }

  })
