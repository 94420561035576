angular.module('services')
  .factory('util', function(_, $filter) {

    var currencyFilter = $filter('currency')

    return {
      abbrev: abbrev,
      camelCase: camelCase,
      dashCase: dashCase,
      percentChange: percentChange,
      snakeCase: snakeCase,
      titleCase: titleCase
    }

    function abbrev(str, n) {
      n = n || 4
      var arr = titleCase(str).split(/[\s|-]/)
      var max = Math.min(n, arr.length)
      var abbrev = ''
      for (var i = 0; i < max; i++) {
        abbrev += arr[i][0]
      }
      return abbrev
    }

    function camelCase(str) {
      return removeExtraSpaces(str.replace(/[^a-zA-Z0-9]/g, ' '))
        .replace(/\s./g, function(c){ return c[1].toUpperCase() })
    }

    function dashCase(str) {
      return camelCase(str).replace(/[A-Z]/g, function(c){ return '-'+c.toLowerCase() })
    }

    function percentChange(startNum, endNum) {
      var amount = endNum - startNum
      var decimal = amount / startNum
      return Math.round(decimal * 100)
    }

    function snakeCase(str) {
      return camelCase(str).replace(/[A-Z]/g, function(c){ return '_'+c.toLowerCase() })
    }

    function titleCase(str) {
      return removeExtraSpaces(removeCamelCase(str))
        .replace(/[\s|-]./g, upperCase)
        .replace(/^./, upperCase)
    }

    function upperCase(str) {
      return str.toUpperCase()
    }

    function removeCamelCase(str) {
      return str.replace(/[A-Z]/g, function(c){ return ' '+c.toLowerCase() })
    }

    function removeExtraSpaces(str) {
      return str.replace(/\s+/g, ' ').replace(/^\s/, '').replace(/\s$/, '')
    }

  })
