angular.module('boc')
  .factory('ordinal', function() {

    ordinal.indicator = indicator
    return ordinal

    function ordinal (i) {
      var num = parseInt(i, 10)
      if (isNaN(num)) throw new TypeError('Expected Number, got ' + i)
      return num + indicator(num)
    }

    function indicator (i) {
      var cent = i % 100
      if (cent >= 10 && cent <= 20) return 'th'
      var dec = i % 10
      if (dec === 1) return 'st'
      if (dec === 2) return 'nd'
      if (dec === 3) return 'rd'
      return 'th'
    }

  })
