angular.module('boc')

  .constant('studioSuffixes', [
    ' Productions',
    ' Studios',
    ' Pictures',
    ' Films, Ltd.',
    ', Inc.'
  ])

  .component('gameJoin', {

    bindings: {
      onJoin: '&'
    },
    template: `
      <form name="$ctrl.signUpForm" validate-and-submit="$ctrl.save()" grid="loose align-center" class="AlertScreen">

        <div grid-el grid="1/1@phone 1/1@tablet">
          <svg-img class="GameIcon--large" use="studio-game-color"></svg-img>
          <div class="PageTitle">New Season <span class="KeepTogether">Sign Up</span></div>
          <div class="PageSubtitle">{{ $ctrl.gameData.league.name }} – {{ $ctrl.gameData.season.name }}</div>
          <div class="Text--large">
            This season starts on
            <b
              ng-if="$ctrl.gameData.league.gameType === 'topfive'"
              class="KeepTogether"
              ng-bind="$ctrl.gameData.season.startWeek | weekToFriday">
            </b>
            <b
              ng-if="$ctrl.gameData.league.gameType === 'studio'"
              class="KeepTogether"
              ng-bind="$ctrl.gameData.season.startWeek | weekToMonday">
            </b>
            and ends on <b class="KeepTogether">{{ $ctrl.gameData.season.endWeek | weekToSunday }}</b>.
          </div>
        </div>

        <div ng-if="$ctrl.err" grid-el grid="1/1@phone 1/1@tablet">
          <div class="Notification--alert has-close">
            <p>{{ $ctrl.err }}</p>
            <button type="button" class="Notification__close" ng-click="$ctrl.err = null">
              <svg-img class="Icon--alert" use="times"></svg-img>
            </button>
          </div>
        </div>

        <div grid-el grid="1/1@phone 1/1@tablet">
          <div class="Panel--general">
            <div class="Panel__header">
              <h1 class="Heading">Your Studio Details</h1>
              <h6 class="Text--small">
                Studio Names, Short Names and Logos are seasonal.
                Changing them will not affect previous
                <keep-together>seasons or replace your user name.</keep-together>
              </h6>
            </div>
            <div class="Panel__body">
              <div grid="tight">
                <div grid-el grid="1/1@phone 1/5@tablet">
                  <div class="Field"">
                    <label for="StudioName" class="Field__label is-required">Studio Name</label>
                    <input
                      id="StudioName"
                      name="name"
                      ng-model="$ctrl.team.name"
                      type="text"
                      class="TextField"
                      ng-class="{ 'has-error': $ctrl.signUpForm.hasErrors('name') }"
                      ng-maxlength="$ctrl.studioNameMax"
                      required>
                    <label class="HelpText">Maximum {{$ctrl.studioNameMax}} characters</label>
                  </div>
                </div>
                <div grid-el grid="1/1@phone shrink@tablet">
                  <div class="Field">
                    <label for="StudioAbbrev" class="Field__label is-required">Short Name</label>
                    <input
                      id="StudioAbbrev"
                      name="abbrev"
                      ng-model="$ctrl.team.abbrev"
                      type="text"
                      class="TextField"
                      ng-class="{ 'has-error': $ctrl.signUpForm.hasErrors('abbrev') }"
                      ng-maxlength="$ctrl.shortNameMax"
                      required>
                    <label class="HelpText">Maximum {{$ctrl.shortNameMax}} characters</label>
                  </div>
                </div>
                <div grid-el grid="1/1@phone shrink@tablet">
                  <div class="Field">
                    <label for="StudioAvatar" class="Field__label">Studio Logo</label>
                    <label class="ImageField" ng-click="$ctrl.toggleAvatar()">
                      <span ng-if="!$ctrl.team.avatar">
                        <span class="Avatar--medium ImageField__thumbnail" alt="{{ $ctrl.team.abbrev || '?' }}"></span>
                        <!--<span class="Link">Use Logo</span>-->
                      </span>
                      <span ng-if="$ctrl.team.avatar">
                        <span class="Avatar--medium ImageField__thumbnail" style="background-image:url({{ $ctrl.team.avatar }})"></span>
                        <span ng-if="$ctrl.canUseAvatar" class="Link">Use Text</span>
                      </span>
                      <!--<input ng-model="$ctrl.team.avatar" id="StudioAvatar" type="file" />-->
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div grid-el grid="1/1@phone shrink@tablet">
          <button class="Button--primary-large Button--full">Build Your New Studio!</button>
        </div>

      </form>
    `,

    controller: function($q, gameData, User, util, studioSuffixes, Team, GameTeam) {

      var STUDIO_NAME_MAX = 40
      var SHORT_NAME_MAX = 4

      var $ctrl = this
      var prevTeam = gameData.latestTeam || {}
      var prevAvatar = prevTeam.avatar || User.current.avatar
      var name = prevTeam.name || studioName(util.titleCase(User.current.username))

      $ctrl.studioNameMax = STUDIO_NAME_MAX
      $ctrl.shortNameMax = SHORT_NAME_MAX
      $ctrl.gameData = gameData
      $ctrl.save = save
      $ctrl.toggleAvatar = toggleAvatar
      $ctrl.canUseAvatar = !!prevAvatar
      $ctrl.team = {
        name: name,
        seasonId: gameData.season.id,
        leagueId: gameData.league.id,
        abbrev: prevTeam.abbrev || util.abbrev(name, SHORT_NAME_MAX),
        avatar: prevAvatar
      }

      function toggleAvatar() {
        return // this is not supported yet
        if ($ctrl.team.avatar) $ctrl.team.avatar = null
        else if (prevAvatar) $ctrl.team.avatar = prevAvatar
      }

      function studioName(name) {
        if (name.length > STUDIO_NAME_MAX) return name.substring(0, STUDIO_NAME_MAX)
        var i = Math.floor(Math.random() * studioSuffixes.length)
        var studioName = name + studioSuffixes[i]
        return studioName.length > STUDIO_NAME_MAX ? name : studioName
      }

      function save() {
        gameData.startUpdate()
        Team.save($ctrl.team)
          .then(saveSuccess, saveError)
          .finally(gameData.endUpdate)
      }

      function saveSuccess(data) {
        var gameTeam = GameTeam.createInstance(data.gameTeam)
        gameData.setLatestTeam(data.team)
        gameData.setGameTeam(gameTeam)
        gameData.setGameTeamEntries(data.gameTeamEntries)
        $ctrl.onJoin()
      }

      function saveError(err) {
        $ctrl.err = err
      }

    }

  })
