;(function(){

  angular.module('boc')
    .component('game', {

      template: `
        <div sticky-container>
          <header sticky class="PageSection SubNav">
            <h1 class="SubNav__title">{{$ctrl.gameData.league.name}}</h1>
            <span class="SubNav__select">
              <select
                ng-model="$ctrl.setSeason"
                ng-model-options="{getterSetter: true}"
                ng-options="season as season.name for season in $ctrl.gameData.league.seasons">
              </select>
            </span>
            <a class="SubNav__more is-hidden@tablet" boc-toggle-name="menu" boc-toggle="sub-menu menu-overlay">
              <svg-img class="Icon" use="grip-vert"></svg>
            </a>
            <ul class="SubNav__links is-visible@tablet">
              <li class="SubNav__link">
                <a season-link>
                  <span ng-if="$ctrl.gameData.userHasJoined">My Picks</span>
                  <span ng-if="!$ctrl.gameData.userHasJoined">Play</span>
                </a>
              </li>
              <li class="SubNav__link"><a season-link="leaderboard">Leaderboard</a></li>
              <li class="SubNav__link"><a season-link="help">Help</a></li>
            </ul>
            <div boc-toggle-name="menu sub-menu" class="BocNavMenu--sub is-hidden@tablet">
              <header class="BocNavMenu__header">
                <div class="BocNavMenu__title">More</div>
                <button type="button" class="BocNavMenu__close" boc-toggle-close="menu menu-overlay">close</button>
              </header>
              <a class="BocNavMenu__link" season-link boc-toggle-close="menu menu-overlay">
                <span ng-if="$ctrl.gameData.userHasJoined">My Picks</span>
                <span ng-if="!$ctrl.gameData.userHasJoined">Play</span>
              </a>
              <a class="BocNavMenu__link" season-link="leaderboard" boc-toggle-close="menu menu-overlay">Leaderboard</a>
              <a class="BocNavMenu__link" season-link="help" boc-toggle-close="menu menu-overlay">Help</a>
            </div>
          </header>
          <ng-outlet class="PageSection"></ng-outlet>
        </div>
      `,

      $routeConfig: [
        { path: '/', name: 'Home', component: 'gamePlay', useAsDefault: true },
        { path: '/picks/:userId', name: 'Picks', component: 'gamePlay' },
        { path: '/join', name: 'Join', component: 'gameJoin' },
        { path: '/leaderboard', name: 'Leaderboard', component: 'gameLeaderboard' },
        { path: '/chatter', name: 'Chatter', component: 'gameChatter' },
        { path: '/help', name: 'Help', component: 'gameHelp' }
      ],

      bindings: {
        $router: '<'
      },

      controller: function($window, _, Team, User, gameData) {

        var $ctrl = this

        $ctrl.gameData = gameData
        $ctrl.setSeason = setSeason
        $ctrl.$routerCanReuse = canReuse
        $ctrl.$routerOnActivate = $ctrl.$routerOnReuse = gameData.gameRouteUpdate
        $ctrl.link = link

        function canReuse() {
          return true
        }

        // hack to trick ng-link into updating when the week changes
        function link(arr) {
          return arr.concat({}, { _: $window.location.href })
        }

        function setSeason(season) {
          if (!arguments.length) return gameData.season
          gameData.changeSeason(season)
        }

      }
    })

})();
