angular.module('services')
  .factory('GameTeamEntry', function($q, _, Service, Model, Movie, Time, $injector, $filter) {

    class GameTeamEntryService extends Service {

      constructor() {
        super()
      }

      url(params) {
        return super.url(params, '/api/game-team-entries')
      }

    }

    class GameTeamEntry extends Model {

      get associations() {
        return {
          movie: 'Movie',
          season: 'gameData.season',
          league: 'gameData.league',
          gameType: 'league.gameType',
          week: 'gameData.week'
        }
      }

      get gameData() {
        return $injector.get('gameData')
      }

      reset(attrs) {
        var entry = this
        var propsToKeep = ['id', 'rank', 'week']
        var data = {}

        for (var prop in this) {
          if (typeof this[prop] === 'function' || prop[0] === '$') continue
          if (propsToKeep.indexOf(prop) !== -1) data[prop] = this[prop]
          else if (this.hasOwnProperty(prop)) data[prop] = null
        }

        _.extend(data, attrs)

        return entry.service
          .save(data)
          .then(savedEntry => _.extend(entry, attrs, savedEntry))
      }

      get isTopfive() {
        return this.gameType === 'topfive'
      }

      get isStudio() {
        return this.gameType === 'studio'
      }

      get isEmpty() {
        return !this.hidden && !this.movieId
      }

      get isNotEmpty() {
        return !!this.movieId
      }

      get isEditable() {
        return this.isTopfive && this.isNotEmpty && this.isNotLocked
      }

      get isNotEditable() {
        return !this.isEditable
      }

      get isRemovable() {
        return this.isNotEmpty && this.isNotLocked
      }

      get isNotRemovable() {
        return !this.isRemovable
      }

      get isFinal() {
        switch (this.gameType) {
          case 'topfive': return this.actualEarnings !== null
          case 'studio': return Time.weekEnded(this.season.endWeek)
        }
      }

      get isNotFinal() {
        return !this.isFinal
      }

      get isLocked() {
        switch (this.gameType) {
          case 'topfive': return !!this.gameData.topfiveLocked
          case 'studio': return this.gameData.get('season.hasStarted') && this.isNotEmpty && this.movie.hasOpened
        }
      }

      get isNotLocked() {
        return !this.isLocked
      }

      get isVoid() {
        return this.isLocked && this.isStudio && this.seasonRank > 10 && this.revenue
      }

      get status() {
        var top5 = this.gameType === 'topfive'
        var locked = this.isLocked
        var final = this.isFinal
        var empty = this.isEmpty
        var movie = this.movie || {}
        var season = this.season || {}

        if (this.hidden) return 'hidden'
        if (empty && (final || locked)) return 'no-pick'
        if (final) return this.isVoid ? 'void' : 'final'
        if (locked) return this.isVoid ? 'void' : 'locked'
        if (empty) return 'empty'
        else if (movie.releaseWeek > season.endWeek) return 'date-alert' // TODO: make this trigger if release date has changed
        if (top5) {
          if (!this.predictedEarnings) return 'incomplete'
          else return 'editable'
        }
        else return '' // blockbuster (studio) has no editable state
      }

      get isCheapSeat() {
        return this.isStudio && this.rank > 10
      }

      get rankDisplay() {
        if (this.isStudio) return this.isCheapSeat ? 'CS' : this.rank
        var entries = this.gameData.gameTeamEntries || []
        var index = entries.indexOf(this) + 1
        return isNaN(index) ? '-' : index
      }

      // hide rank for movies that have not opened, since it's random
      get seasonRankDisplay() {
        if (this.hidden || this.isNotLocked || !this.revenue) return ''
        return this.seasonRank
      }

      get revenueWithBonus() {
        return (this.revenue || 0) + (this.bonus || 0)
      }

      get bonusLabel() {
        var bp = this.bonusPercent
        return bp ? bp + '% Bonus' : 'No Bonus'
      }

      get bonusDisplay() {
        return this.bonusPercent ? '+ ' + $filter('revenue')(this.bonus) : '---'
      }

      get editedDisplay() {
        var date = Time.serverDate(this.editedAt)
        if (!date.isValid()) return ''
        return 'Edited ' + date.format('ddd @ h:mma') + ' EST'
      }

      get predictedChange() {
        var lastEarning = _.get(this, 'movie.recentEarnings[0]') || {}
        if (typeof lastEarning.weekend !== 'number') return 'New'
        if (!this.predictedEarnings) return '---'
        var amount = this.predictedEarnings - lastEarning.weekend
        var decimal = amount / lastEarning.weekend
        var notDoneTyping = decimal < -0.99
        return notDoneTyping ? '---' : $filter('number')(Math.round(decimal * 100)) + '%'
      }

    }

    return Service.register('GameTeamEntry', GameTeamEntryService, GameTeamEntry)

  })
