angular.module('boc')
  .component('gameOver', {

    template: `
      <div class="AlertScreen">
        <img src="/img/mcfly.jpg" class="GameIcon--large" />
        <div class="PageTitle">Hey, Doc, <keep-together>You Better <keep-together>Back Up</keep-together></keep-together></span></div>
        <div class="PageSubtitle">We Don't Have Enough Road <keep-together>to Get Up to 88</keep-together></div>
        <p class="Text--large">Seriously though, <keep-together>joining past seasons</keep-together> <keep-together>is not possible without <keep-together>a flux capacitor.</keep-together></keep-together></span></p>
        <p ng-if="$ctrl.currentSeasonActive" class="Text">
          <a ng-href="{{ $ctrl.currentSeasonUrl }}" class="Button">Back to the Future</a>
        </p>
      </div>
    `,

    controller: function(gameData, Time) {
      var $ctrl = this
      $ctrl.$onInit = function() {
        var lastWeek = gameData.get('league.currentSeason.endWeek')
        if ($ctrl.currentSeasonActive = lastWeek <= Time.currentWeek) {
          $ctrl.currentSeasonUrl = gameData.url(gameData.league.currentSeason.id, Time.currentWeek)
        }
      }
    }

  })
