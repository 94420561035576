// Given the following media sizes: ['phone', 'phablet', 'tablet', 'desktop']

// <div at-media="phone">phone</div>
// <div at-media="phone, phablet and tablet">phone, phablet, tablet</div>
// <div at-media="phone to tablet">phone, phablet, tablet</div>
// <div at-media="tablet and above">tablet, desktop (and any other added later larger than desktop)</div>
// <div at-media="tablet and below">tablet, phone (and any other added later smaller than phone)</div>


angular.module('boc')

  .factory('atMedia', function(_, $window, $rootScope) {
    var lastBreakpointName
    var api = {}
    var internals = {}
    var breakpointMap = {
      phone: 0,
      tablet: 650,
      desktop: 960
    }

    parseBreakpointMap(breakpointMap)
    setBreakpoint()
    angular.element($window).on('resize', setBreakpoint)

    return $rootScope.atMedia = api

    // Updates the current breakpoint
    function setBreakpoint() {
      var match = null
      for (var i = 0; i < internals.breakpoints.length; i++) {
        var breakpoint = internals.breakpoints[i]
        if ($window.innerWidth < breakpoint.width) break
        else match = breakpoint.name
      }

      if (match === lastBreakpointName) return

      function updateBreakpoint() {
        lastBreakpointName = match
        for (var key in api) { delete api[key] }
        api[match] = true
      }

      if ($rootScope.$$phase) updateBreakpoint()
      else $rootScope.$apply(updateBreakpoint)
    }

    function parseBreakpointMap(breakpointMap) {
      internals.breakpointMap = breakpointMap
      internals.breakpoints = _.sortBy(_.map(breakpointMap, parseBreakpoint), 'width')
      internals.breakpointNames = _.map(internals.breakpoints, 'name')
    }

    function parseBreakpoint(width, name) {
      return { name: name, width: width }
    }

  })
