angular.module('services')
  .factory('Time', function(_, $interval, $http, $timeout, $rootScope, moment) {

    /* mock time for testing

      window.mockTime.set({
        week: 4017,
        day: 4,
        hours: 11,
        minutes: 59,
        seconds: 45,
        offset: -1 // one hour behind the server
      })

    */
    var mock = window.mockTime = {
      nowOffset: 0,
      now: now,
      set: function(opts) {
        var d = weekToDate(opts.week || api.currentWeek)
        if (opts.seconds) d.setSeconds(opts.seconds)
        if (opts.minutes) d.setMinutes(opts.minutes)
        if (opts.hours) d.setHours(opts.hours)
        if (opts.day) {
          var date = d.getDate()
          var day = d.getDay()
          d.setDate(date + (opts.day - day))
        }
        api.localOffset = (opts.offset || 0) * 60 * 60 * 1000
        mock.nowOffset = Date.now() - d // convert mock date to ms
        $timeout(function(){ update(true) })
        updateOnTheMinute()
      }
    }


    // Set week 4000 to Jan 2, 2017
    var releaseDate = getReleaseDate()
    var START_WEEK_NUMBER = 4000
    var DAY_LENGTH = 1000 * 60 * 60 * 24
    var WEEK_LENGTH = DAY_LENGTH * 7
    var WEEK_ONE_START = +releaseDate - WEEK_LENGTH * START_WEEK_NUMBER
    var DAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    var DAY_NAMES_COMPACT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    var MONTH_NAMES_COMPACT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    var ONE_MINUTE = 1000 * 60
    var updateDelay = 0
    var updateInterval
    var updateIntervalDelay
    var localNow = new Date()

    var api = window.time = {
      activeWeek: null,
      currentWeek: dateToWeek(localNow),
      currentDay: localNow.getDay() || 7, // force sunday to 7 instead of 0
      localOffset: 0,
      serverDate: serverDate,
      serverNow: serverNow,
      serverWeek: serverWeek,
      sync: sync,
      toServerTime: toServerTime,
      weekDayDisplay: weekDayDisplay,
      weekEnded: weekEnded,
      weekendStarted: weekendStarted,
      weekToDate: weekToDate,
      dateToWeek: dateToWeek
    }

    sync()

    return api

    // allows stubbing current time for testing
    function now() {
      return Date.now() - mock.nowOffset
    }

    function update(force) {
      var oldWeek = api.currentWeek
      var oldDay = api.currentDay
      var newWeek = api.currentWeek = serverWeek()
      var newDay = api.currentDay = serverNow().getDay() || 7 // forces sunday to 7 instead of 0
      $rootScope.$broadcast('timeUpdate')
      if (force || oldWeek !== newWeek || oldDay !== newDay) $rootScope.$broadcast('dayUpdate')
    }

    function updateOnTheMinute() {
      var serverMilliseconds = now() - api.localOffset
      var nextMinute = ONE_MINUTE - (serverMilliseconds % ONE_MINUTE)
      $timeout(updateEveryMinute, nextMinute)
    }

    function updateEveryMinute() {
      $interval.cancel(updateInterval)
      updateInterval = $interval(update, ONE_MINUTE)
      update()
    }

    function sync() {
      var requestTime = now()
      $http.get('/api/current-time').then(syncSuccess, syncError)

      function syncSuccess(resp) {
        updateDelay = 0
        if (resp && resp.data) {
          var responseTime = now()
          var d = resp && resp.data || {}
          var serverTimeDate = new Date(d.year, d.month, d.date, d.hour, d.minute, d.second, d.millisecond)
          var serverTime = +serverTimeDate // convert to ms
          var clientTime = (requestTime + responseTime) / 2
          api.zone = d.zone
          api.localOffset = clientTime - serverTime || 0 // fall back to client time if something is messed up
        }
        updateOnTheMinute()
      }

      function syncError() {
        updateDelay = updateDelay ? updateDelay * 2 : 1
        $timeout(sync, updateDelay * 1000)
      }
    }

    function weekEnded(num) {
      return api.currentWeek > num // TODO: account for any holiday weekends
    }

    function weekendStarted(num) {
      return api.currentWeek > num || (api.currentWeek === num && (!api.currentDay || api.currentDay > 4))
    }

    function weekDayDisplay(week, offset, compact) {
      offset = offset || 0
      var date = new Date((week * WEEK_LENGTH) + WEEK_ONE_START + offset * DAY_LENGTH + DAY_LENGTH / 2)
      var days = compact ? DAY_NAMES_COMPACT : DAY_NAMES
      var months = compact ? MONTH_NAMES_COMPACT : MONTH_NAMES
      return days[date.getDay()] + ' ' + months[date.getMonth()] + ' ' + date.getDate()
    }

    function getReleaseDate() {
      var date = new Date()
      date.setMilliseconds(0)
      date.setSeconds(0)
      date.setMinutes(0)
      date.setHours(0)
      date.setDate(2)
      date.setMonth(0) // January
      date.setYear(2017)
      return date
    }

    function toServerTime(date) {
      if (typeof date !== 'object') date = new Date(date)
      if (date.isServerTime) return date
      else date.isServerTime = true
      return new Date(date.getTime() - api.localOffset)
    }

    function serverDate(date) {
      return moment.tz(date, api.zone)
    }

    function dateToWeek(date) {
      return Math.floor((date - WEEK_ONE_START) / WEEK_LENGTH)
    }

    function weekToDate(week) {
      return new Date(WEEK_ONE_START + week * WEEK_LENGTH)
    }

    function serverNow() {
      return toServerTime(now())
    }

    function serverWeek() {
      return dateToWeek(serverNow())
    }

  })
