angular.module('boc')
  .component('gameSelectMovie', {

    bindings: {
      onSelect: '&'
    },

    template: `
      <div class="Overlay">
        <header class="Overlay__header">
          <div grid="loose spaced nowrap align-baseline">
            <div pl-grid-el pl-grid>
              <h1 class="Overlay__title">Available Movies</h1>
            </div>
            <div pl-grid-el pl-grid="shrink@phone">
              <button class="Overlay__close" ng-click="$ctrl.cancel()">
                <svg-img class="Icon" use="times"></svg>
              </button>
            </div>
          </div>
        </header>
        <section class="Overlay__content">
          <div ng-if="$ctrl.noMoreMovies" class="AlertScreen">
            <img src="/img/vizzini.jpg" class="GameIcon--large" />
            <div class="PageTitle">He's too late. See?</div>
            <div class="PageSubtitle">It may be “inconceivable”, <keep-together>but there are no more</keep-together> <keep-together>movies to choose from.</keep-together></div>
            <div class="Text--large">Even the soul of you father, <keep-together>dead these twenty years,</keep-together><br> cannot guide your sword <keep-together>to find them.</keep-together></div>
            <p class="Text">
              <button type="button" class="Button" ng-click="$ctrl.cancel()">As You Wish</button>
            </p>
          </div>
          <div ng-if="!$ctrl.noMoreMovies" ng-repeat="movieGroup in $ctrl.movieGroups" grid="tight fixed">
            <div grid-el grid="1/1@phone">
              <h2 ng-if="movieGroup.title" class="SectionTitle">{{movieGroup.title}}</h2>
            </div>
            <div ng-repeat="movie in movieGroup.movies" grid-el grid="1/2@phone 1/4@tablet 1/5@desktop">
              <movie-card movie="movie" on-select="$ctrl.selectMovie"></movie-card>
            </div>
          </div>
        </section>
      </div>
    `,

    controller: function(_, $q, $scope, Season, gameData) {
      var $ctrl = this

      $ctrl.$onInit = init
      $scope.$on('dayUpdate', loadData)

      function init() {
        $ctrl.cancel = $ctrl.selectMovie = $ctrl.onSelect()
        loadData()
      }

      function loadData(){
        return $q.all({
          movies: gameData.getMovies(),
          entries: gameData.gameTeamEntries
        })
        .then(setData)
      }

      function setData(data) {
        var isTopfive = gameData.league.isTopfive
        var isStudio = gameData.league.isStudio
        var entryMovieIds = _.compact(_.map(data.entries, 'movieId'))
        var movies = _.filter(data.movies, isUnselected)
        var groups = $ctrl.movieGroups = []

        if (isStudio) {
          addGroup({ movies: _.filter(movies, isUpcoming) }) // No title
        }

        if (isTopfive) {
          addGroup({ title: 'New Releases', movies: _.sortBy(_.filter(movies, newStatus), sortTitle) })
          addGroup({ title: 'Expanding Releases', movies: _.sortBy(_.filter(movies, expandingStatus), sortTitle) })
          addGroup({ title: "Last Week's Top Movies", movies: _.sortBy(_.filter(movies, topStatus), sortPrevWeekend) })
        }

        $ctrl.noMoreMovies = !groups.length

        function addGroup(data) {
          if (data.movies.length) groups.push(data)
        }

        function isUnselected(m) {
          return entryMovieIds.indexOf(m.id) === -1 && m.status !== 'hidden'
        }

        function isUpcoming(m) {
          return (gameData.season.hasNotStarted || m.hasNotOpened) && m.releaseWeek <= gameData.season.endWeek
        }

        function topStatus(m) {
          return !m.status || m.status === 'top'
        }

        function newStatus(m) {
          return m.status === 'new'
        }

        function expandingStatus(m) {
          return m.status === 'expanded'
        }

        function sortTitle(movie) {
          return movie.title.toLowerCase()
        }

        function sortPrevWeekend(movie) {
          var earning = _.find(movie.recentEarnings || [], { week: gameData.week - 1 }) || {}
          return earning.weekend ? 0 - earning.weekend : Infinity
        }
      }
    }

  })
