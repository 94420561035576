;(function(){

  var $ = angular.element

  angular.module('boc')
    .run(extendFormController)
    .directive('form', formDirective)
    .directive('ngForm', formDirective)
    .directive('touchOn', touchOn)

  function extendFormController(_, $q, formDirective, ngModelDirective) {

    var FormController = formDirective[0].controller
    var NgModelController = ngModelDirective[0].controller

    // Marks all existing fields as dirty and touched to force error display
    // while allowing new fields to behave as normal. Usefull in cases where
    // you can add new subforms (like add many).
    function dirtyTouchAllInvalidFields(form) {
      for (var key in form) {
        if (_.startsWith(key, '$')) continue

        var fieldCtrl = form[key] || {}
        if (fieldCtrl instanceof FormController) dirtyTouchAllInvalidFields(fieldCtrl)
        else dirtyTouchField(fieldCtrl)
      }
    }

    function dirtyTouchField(fieldCtrl, forceDirtyTouch) {
      if (!(fieldCtrl instanceof NgModelController)) return
      fieldCtrl.$validate()
      if (forceDirtyTouch || fieldCtrl.$invalid) {
        fieldCtrl.$setDirty()
        fieldCtrl.$setTouched()
      }
    }

    _.extend(FormController.prototype, {

      // Sets one or more inputs to dirty/touched and validates them.
      // This is useful if you want a control to trigger a model change and validation on another form input.
      validateFields: function() {
        var form = this
        _.each(arguments, function(property) {
          var fieldCtrl = _.get(form, property) // allow nested paths
          dirtyTouchField(fieldCtrl, true) // true = force dirty touch
        })
      },

      hasErrors: function(fieldName, opts) {
        opts = opts || {}
        var form = this
        var field = form[fieldName]
        if (!field) return // can't throw errors here since form fields could be initialized in sub templates (loaded async)

        return field.$touched && field.$dirty && !_.isEmpty(_.omit(field.$error, opts.ignore || []))
      },

      // Wraps the form submit action so that it returns a rejected promise (e.g. for spinner-click) if the form is invalid.
      validateAndSubmit: function(callback) {
        var form = this
        form.$setSubmitted()
        dirtyTouchAllInvalidFields(form)
        if (!form.$valid) {
          var formElement = form.$$formElement
          var field = formElement.find('.ng-invalid:not(form):not(ng-form):not([ng-form])').first()
          field.focus()
          if (field.data('select2')) field.data('select2').focus()

          return $q.reject()
        }

        // don't submit unless we are ready
        if (!callback || form.$submitting) return $q.resolve()

        form.$submitting = true
        return $q
          .resolve(callback())
          .finally(function(){
            form.$submitting = false
          })
      }

    })
  }

  function formDirective(_) {
    return {
      require: 'form',
      link: function(scope, element, attrs, controller) {
        controller.$$formElement = element
        var hasAction = 'action' in attrs
        var validateOnSubmit = 'validateAndSubmit' in attrs
        var preValidated

        // Use `form-controller-as="mySubFormName"` in a nested repeated ng-form directive to create a local scope reference to the subform.
        if (attrs.formControllerAs) scope[attrs.formControllerAs] = controller

        // validate on submit if custom attribute/handler is present
        if (validateOnSubmit) element.on('submit', validateAndSubmit)

        function validateAndSubmit(event) {
          if (hasAction) {
            if (preValidated) return
            else event.preventDefault()
          }
          return scope.$apply(function() {
            controller
              .validateAndSubmit(submit)
              .then(hasAction && resubmit)
          })
        }

        function submit() {
          return scope.$eval(attrs.validateAndSubmit)
        }

        function resubmit() {
          preValidated = true
          element.submit()
          preValidated = false
        }
      }
    }
  }

  function touchOn($rootScope) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, modelCtrl) {
        if (!attrs.touchOn) return
        // coppied from ngModel postLink
        element.on(attrs.touchOn, function() {
          if (modelCtrl.$touched) return
          else if ($rootScope.$$phase) scope.$evalAsync(modelCtrl.$setTouched)
          else scope.$apply(modelCtrl.$setTouched)
        })
      }
    }
  }

})();
