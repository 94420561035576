angular.module('boc')

  .component('gameBonusTopfive', {

    template: `
      <div class="Notification--general" ng-if="$ctrl.mainMessage && !$ctrl.resultsFinal">
        <p><b ng-bind="$ctrl.mainMessage"></b><span class="Warning" ng-bind="$ctrl.timer"></span><span ng-bind="$ctrl.secondaryMessage"></span></p>
      </div>
      <div class="Notification--primary" ng-if="$ctrl.resultsFinal">
        <p>This week's results are final.</p>
      </div>
    `,

    controller: function($element, $interval, gameData, Time) {
      var $ctrl = this
      var updateInterval

      $ctrl.$onInit = onInit
      $ctrl.$onDestroy = onDestroy

      function onInit() {
        update()
        updateInterval = $interval(update, 1000, false)
      }

      function onDestroy() {
        $interval.cancel(updateInterval)
      }

      function update() {
        var activeWeek = gameData.week
        var seasonWeek = gameData.get('season.currentWeek')
        var timeDay = Time.currentDay
        var weekOffset = seasonWeek - activeWeek
        var serverNow = Time.serverNow()
        var serverHours = serverNow.getHours()
        var hours = weekOffset * (24 * 7) + (Time.currentDay - 1) * 24 + serverHours

        var HOUR = 1000 * 60 * 60
        var DAY = HOUR * 24

        gameData.topfiveLocked = false

        var now = Time.serverNow().getTime()
        var thisWeek = gameData.week
        var monMidnight = Time.weekToDate(thisWeek).getTime()
        var wedNoon = monMidnight + DAY * 2 + HOUR * 12
        var thursNoon = wedNoon + DAY
        var friNoon = thursNoon + DAY
        var mon7p = monMidnight + DAY * 7 + HOUR * 19

        if (now < wedNoon) bonus20() // wednesday at noon
        else if (now < thursNoon) bonus10() // thursday at noon
        else if (now < friNoon) predictionsLockSoon() // friday at noon
        else if (now > mon7p) resultsFinal() // monday at 7pm - TODO: make this based on game updated time
        else if (gameData.get('season.currentWeek') > thisWeek) estimatedResults() // season has advanced
        else predictionsLocked()

        function timeUntil(time) {
          var seconds = Math.round((time - now) / 1000)
          var hours = Math.floor(seconds / 60 / 60)
          seconds -= hours * 60 * 60
          var minutes = Math.floor(seconds / 60)
          seconds -= minutes * 60
          return [zeroPad(hours), zeroPad(minutes), zeroPad(seconds)].join(':')
        }

        function zeroPad(num) {
          var str = num.toString()
          return str.length === 1 ? '0' + str : str
        }

        function bonus20() {
          $ctrl.mainMessage = '20% Bonus expires in '
          $ctrl.timer = timeUntil(wedNoon)
          $ctrl.secondaryMessage = ' (Noon Wed. EST)'
        }

        function bonus10() {
          $ctrl.mainMessage = '10% Bonus expires in '
          $ctrl.timer = timeUntil(thursNoon)
          $ctrl.secondaryMessage = ' (Noon Thurs. EST)'
        }

        function predictionsLockSoon() {
          $ctrl.mainMessage = 'Predictions lock in '
          $ctrl.timer = timeUntil(friNoon)
          $ctrl.secondaryMessage = ' (Noon Fri. EST)'
        }

        function predictionsLocked() {
          gameData.topfiveLocked = true
          $ctrl.mainMessage = 'Predictions are locked.'
          $ctrl.timer = null
          $ctrl.secondaryMessage = ' Check back on Sunday afternoon for estimated results.'
        }

        function estimatedResults() {
          gameData.topfiveLocked = true
          $ctrl.mainMessage = 'Estimated results below.'
          $ctrl.timer = null
          $ctrl.secondaryMessage = ' Check back on Monday evening for final results.'
        }

        function resultsFinal() {
          gameData.topfiveLocked = true
          $ctrl.resultsFinal = true
        }

      }
    }

  })
