;(function(){

  angular.module('boc')
    .directive('input', function($filter) {

      return {
        require: '?ngModel',
        link: function ($scope, $element, $attrs, ctrl) {
          if (!ctrl) return console.warn("Custom input directive requires ngModel.", $element)
          var parsers = ctrl.$parsers
          var formatters = ctrl.$formatters

          // DOLLARS
          if ($attrs.type === 'dollars') {
            formatters.unshift(function() {
              return format(ctrl.$modelValue)
            })
            parsers.unshift(function(viewValue) {
              var plainNumber = viewValue.replace(/\D/g, '') || null
              $element.val(format(plainNumber))
              return plainNumber
            })
          }

          function format(str) {
            var dollars = $filter('number')(str)
            return dollars ? '$' + dollars : ''
          }

        }
      }

    })

})();
