angular.module('filters')

  .filter('revenue', function($filter) {
    return function(val) {
      return val ? $filter('currency')(val, '$', 0) : '$0,000,000'
    }
  })

  // 1.23B
  // 123.4M
  // 123.4K
  .filter('revenueAbbrev', function($filter) {
    var B = 1000000000
    var M = 1000000
    var K = 1000
    return function(val) {
      if (val >= B) return $filter('currency')(val / B, '$', 2) + 'B'
      if (val >= M) return $filter('currency')(val / M, '$', 1) + 'M'
      if (val >= K) return $filter('currency')(val / K, '$', 1) + 'K'
      return val ? $filter('currency')(val, '$', 0) : '$0'
    }
  })
