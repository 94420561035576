angular.module('boc')
  .directive('seasonLink', function($rootScope, $location) {
    var SEASON_RX = /((topfive|blockbuster|leagues\/\d+)(\/season\/\d+)*)(.*)/
    var WEEK_RX = /\/week\/\d+/
    return {
      restrict: 'A',
      scope: {
        path: '@seasonLink'
      },
      link: function($scope, $element) {
        $scope.$on('$destroy', $rootScope.$on('$locationChangeSuccess', updateHref))

        // /slug
        // /slug/section
        // /slug/season/:seasonId/section
        // /slug/season/:seasonId/week/:week/section
        // /league/:leagueId
        // /league/:leagueId/section
        // /league/:leagueId/season/:seasonId/section
        // /league/:leagueId/season/:seasonId/week/:week/section

        // Keep url through season/:seasonId and replace the rest with the path
        function updateHref() {
          var url = $location.url()
          var seasonUrl = url.replace(SEASON_RX, '$1')
          var nonWeekUrl = url.replace(WEEK_RX, '')
          var newUrl = $scope.path ? seasonUrl + '/' + $scope.path : seasonUrl
          var isActive = nonWeekUrl === newUrl

          $element
            .attr('href', newUrl)
            .closest('[class*="__link"]')
            .toggleClass('is-active', isActive)
        }
      }
    }
  })
