angular.module('services')
  .factory('Service', function(_, $http, Model) {

    var registeredModels = {}

    class Service {

      constructor() {
        this.current = null
        _.bindAll(this, 'createInstance', 'currentGetterSetter', 'deserialize', 'destroy', 'get', 'list', 'save', 'setCurrentOnScopeCtrl')
      }

      url(params, path) {
        if (params && params.id) {
          return `${path}/${params.id}`
        }
        return path
      }

      // Accepts: get(1), get({ id: 1 }), get({ foo: 1, bar: 2 })
      get(params, config) {
        if (typeof params !== 'object') params = { id: params }
        else if (!params.id && typeof config !== 'object') config = { params: params }
        return $http.get(this.url(params), config).then(this.deserialize)
      }

      list(config) {
        return $http.get(this.url(config), config).then(this.deserialize)
      }

      save(model, config) {
        const method = model.id ? 'put' : 'post'
        const url = this.url(model)
        return $http[method](url, this.serialize(model), config).then(this.deserialize)
      }

      destroy(params, config) {
        if (typeof params != 'object') params = { id: params }
        return $http.delete(this.url(params), config).then(this.deserialize)
      }

      createInstance(attrs) {
        return this.Model.createInstance(attrs)
      }

      currentGetterSetter(attrs) {
        if (!arguments.length) return this.current
        return this.current = attrs ? this.createInstance(attrs) : null
      }

      setCurrentOnScopeCtrl($scope, prop) {
        var service = this
        return $scope.$watch(
          function(){ return service.current },
          function(current) {
            $scope.$ctrl[prop] = service.createInstance(current)
          }
        )
      }

      serialize(attrs) {
        var serialized = {}
        // TODO: enable this if we ever need a whitelist
        // if (this.Model.attrs) attrs = _.pick(attrs, this.Model.attrs) // limit to whitelisted attrs if set
        for (var prop in attrs) {
          var val = attrs[prop]
          if (prop[0] === '$' || !attrs.hasOwnProperty(prop) || typeof val === 'function') continue
          serialized[prop] = !!val && typeof val.serialize === 'function' ? val.serialize() : angular.copy(val)
        }
        return serialized
      }

      deserialize(attrs) {
        attrs = attrs && 'data' in attrs ? attrs.data : attrs
        if (_.isArray(attrs)) return _.map(attrs, this.deserialize)
        if (!_.isObject(attrs)) return attrs
        return this.createInstance(attrs)
      }
    }

    Service.register = (className, ServiceClass, ModelClass) => {
      if (registeredModels[className]) throw new Error(`A ${className} model is already defined.`)
      var service = new ServiceClass()
      if (ModelClass) {
        ModelClass.prototype.className = className
        ModelClass.prototype.service = service
      } else {
        ModelClass = Model
      }
      ServiceClass.prototype.Model = ModelClass
      ModelClass.prototype.classes = registeredModels
      // ModelClass.prototype.Class = ModelClass
      registeredModels[className] = ModelClass
      return service
    }

    return Service

  })
